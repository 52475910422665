//the main page for property/complex (shown to BD only), recommend adding assets in here
import { Alert, CircularProgress, Typography } from "@mui/material";
import {
  MainContainer,
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import { useEffect, useState } from "react";
import {
  deleteUnit,
  exportProperties,
  getFacility,
  getFacilityUnits,
} from "../services/property.service";
import { useSelector } from "react-redux";
import Permit from "../util/permit";
import {
  formatCurrency,
  isEmptyObject,
  isEmptyString,
  isNullUndefined,
  sortAlphaNum,
} from "../util/util";
import BulkUpload from "../components/modals/bulkUpload";
import Inspections from "../components/modals/inspections";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Delete, Edit, NoteAdd, Visibility } from "@mui/icons-material";
import { ResponsiveRow } from "../constants/layout.constants";
import ViewInspections from "../components/modals/viewInspections";
import PropertyModal from "../components/modals/rentals/properties/propertyModal";
import SplitButton from "../components/splitButton";
import UnitModal from "../components/modals/rentals/properties/unitModal";
import { issueResponseMessage } from "../actions/message";
import ConfirmationModal from "../components/modals/confirmation";
import {
  listPropertyInspection,
  listUnitInspection,
} from "../services/inspections.service";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import { useNavigate } from "react-router-dom";
import kodi_theme from "../themes/kodiThemeProvider";
import PropertySubscriptionServiceItem from "../components/modals/rentals/properties/propertySubscriptionServiceItem";
import { fetchSubscription } from "../services/subscription.service";

export default function Property() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [BulkModal, setBulkModal] = useState(false);
  const [InspectModal, setInspectModal] = useState(false);
  const [ViewModal, setViewModal] = useState(false);
  const [facilityUnits, setFacilityUnits] = useState([]);
  const [inspect, setInspect] = useState("");
  const [contract, setContract] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [task, setTask] = useState("");
  const user = useSelector((state) => state.auth.user);
  const [pageNo] = useState(0);
  const [unitModal, setUnitModal] = useState(false);
  const [propertyModal, setPropertyModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [severityText, setSeverityText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [positiveText, setPositiveText] = useState("");
  const [negativeText, setNegativeText] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [inspectionRecords, setInspectionRecords] = useState([]);
  const [subscription, setSubscription] = useState();

  const propertyButtons = [
    {
      label: "PROPERTY ACTIONS",
      roles: false,
      services: false,
    },
    {
      action: handleAddPropertyClick,
      label: "Add Property",
      roles: "BLDIR,SYSAD",
      services: "PREMIUM,HOA,CORE,LEAN,MAINT",
    },
    {
      action: handleEditPropertyClick,
      label: "Edit Property",
      roles: "BLDIR,SYSAD",
      services: "PREMIUM,HOA,CORE,LEAN,METER,MAINT,BLWTR",
    },
    {
      action: exportProperty,
      label: "Export Property",
      roles: "BLDIR,BLDMG,SYSAD,CS",
      services: "PREMIUM,HOA,CORE,LEAN,MAINT",
    },
  ];

  const unitsButtons = [
    {
      label: "UNIT ACTIONS",
      roles: false,
      services: false,
    },
    {
      action: handleAddUnitClick,
      label: "Add Unit",
      roles: "BLDIR,SYSAD",
      services: "PREMIUM,HOA,CORE,LEAN,MAINT",
    },
  ];
  const inspectionButtons = [
    {
      label: "INSPECTION ACTIONS",
      roles: false,
      services: false,
    },
    {
      action: handleInspectPropertyClick,
      label: "Add Inspection record",
      roles: "BLDIR,SYSAD",
      services: "PREMIUM,HOA,CORE,MAINT",
    },
    {
      action: handleViewPropertyInspectionsClick,
      label: "View inspections",
      roles: "BLDIR,SYSAD",
      services: "PREMIUM,HOA,CORE,MAINT",
    },
  ];

  useEffect(() => {
    if (!unitModal) {
      //reload the units on closing of the modal
      setLoading(true);
      getFacilityUnits(user.proxyFacility, pageNo, false)
        .then((response) => {
          if (!isNullUndefined(response)) {
            setFacilityUnits(response.data);
          } else {
            navigate("/dashboard");
          }
        })
        .finally(() => {
          setLoading(false);
          setSelectedUnit(null);
        });
    }
  }, [unitModal, user.proxyFacility, pageNo, navigate]);

  useEffect(() => {
    if (user.proxyFacility != null) {
      setLoading(true);
      getFacility(user.proxyFacility)
        .then((facility) => {
          if (!isNullUndefined(facility) && !isNullUndefined(facility.units)) {
            setFacilityUnits(facility.units);
            setSelectedProperty(facility);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user.proxyFacility]);

  useEffect(() => {
    if(user.proxyRole === "SYSAD" || user.proxyRole === "BLDIR"){
      fetchSubscription(user.proxySubscription).then((response) => {
        let data = response.data;
        setSubscription(data);
      });
    }
  }, [user.proxySubscription]);

  function exportProperty() {
    exportProperties();
  }

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false);
    setSelectedUnit(null);
    setTitleText("");
    setSeverityText("");
    setBodyText("");
    setPositiveText("");
    setNegativeText("");
  };

  function handleAddUnitClick() {
    //send an empty object
    setSelectedUnit({});
    setUnitModal(true);
  }

  function handleAddPropertyClick() {
    // let data = {
    //   id: null,
    //   name: "",
    //   description: "",
    //   latitude: "",
    //   longitude: "",
    //   lrNumber: "",
    //   units: [],
    //   workOrderRequest: false,
    //   address: {
    //     address1: "",
    //     address2: "",
    //     city: "",
    //     countryCd: "",
    //     fullAddress1: "",
    //     id: "",
    //   },
    //   facilityPayInfo: {
    //     mpesaKey: "",
    //     mpesaPassKey: "",
    //     mpesaSecret: "",
    //     mpesaPaybill: "",
    //     payInvoice: false,
    //     hideBalanceBroughtForward: false,
    //     mpesaBusinessShortcode: "",
    //     bankInfo: {
    //       id: "",
    //     },
    //   },
    // };
    // setSelectedProperty(data)
    setSelectedProperty({});
    setPropertyModal(true);
  }

  function handleEditPropertyClick() {
    // setSelectedProperty(property)
    setPropertyModal(true);
  }

  function handleInspectPropertyClick() {
    setInspectModal(true);
    setInspect("Property");
    setContract(selectedProperty);
    setTask("Inspect");
  }

  function handleViewPropertyInspectionsClick() {
    setViewModal(true);
    setInspect("Property");

    setContract(selectedProperty);
    listPropertyInspection(selectedProperty.id, new Date()).then(
      (inspectionRecords) => setInspectionRecords(inspectionRecords)
    );
  }

  function handleInspectUnitClick(unit) {
    setSelectedUnit(unit);
    setInspectModal(true);
    setInspect("Unit");
    setContract(unit);
    setTask("Inspect");
  }

  function handleViewUnitInspectionsClick(unit) {
    setSelectedUnit(unit);
    setViewModal(true);
    setInspect("Unit");
    setContract(unit);
    listUnitInspection(unit.id, new Date()).then((inspectionRecords) =>
      setInspectionRecords(inspectionRecords)
    );
  }

  const handleEditSingleUnitClick = (unit) => {
    setSelectedUnit(unit);
    setUnitModal(true);
  };

  const handleDeleteUnitModal = (unit) => {
    setSelectedUnit(unit);
    let title = "Confirm Delete Unit '" + unit.name + "'";
    let body = "Are you sure you want to permanently delete this unit?";
    setTitleText(title);
    setTask("Delete");
    setSeverityText("warning");
    setBodyText(body);
    setPositiveText("Delete Unit");
    setNegativeText("Cancel");
    setShowConfirmationModal(true);
  };

  const handleDeleteUnit = () => {
    deleteUnit(selectedUnit).then((response) => {
      if (!isNullUndefined(response)) {
        issueResponseMessage(response);
        setLoading(true);
        getFacility(user.proxyFacility)
          .then((facility) => {
            setFacilityUnits(facility.units);
            setSelectedProperty(facility);
          })
          .finally(() => {
            setLoading(false);
          });
      }
      handleConfirmationClose();
    });
  };

  function handlePropertyModalClose(facilityId) {
    //reload the facility if save or update
    if (!isEmptyString(facilityId) || !isNullUndefined(facilityId)) {
      // TODO: This is not currently working, need to figure out why but not a priority for now
      // if(isNullUndefined(selectedProperty.id)){
      // 	//execute proxy to the new facility if save
      // 	dispatch(clearProxy())
      // 	dispatch({
      // 		type: PROXY_FAC,
      // 		payload: {proxyFacility: facilityId},
      // 	})
      // 	dispatch(
      // 		executeProxy(user.proxyRole, user.proxySubscription, facilityId)
      // 	)
      // }
      getFacility(facilityId).then((facility) => {
        if (!isNullUndefined(facility)) {
          setFacilityUnits(facility.units);
          setSelectedProperty(facility);
        }
      });
    } else {
      //see if there is a facility proxied
      if (!isEmptyString(user.proxyFacility)) {
        getFacility(user.proxyFacility).then((facility) => {
          if (!isNullUndefined(facility)) {
            setFacilityUnits(facility.units);
            setSelectedProperty(facility);
          }
        });
      }
    }
    setPropertyModal(false);
    setSelectedProperty(null);
  }

  const unitColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 230,
      flex: 2.3,
      sortComparator: sortAlphaNum,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 300,
      flex: 3,
      valueGetter: (params) => params.value?.name,
      type: "string",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1.5,
      valueGetter: (params) => params.value?.name === "Available" ? "Vacant" : params.value?.name,
      type: "string",
    },
    {
      field: "sqft",
      headerName: "Square Feet",
      minWidth: 115,
      flex: 1.15,
      valueFormatter: (params) => formatCurrency(params.value),
      type: "number",
    },
    {
      field: "rent",
      headerName: "Rent",
      minWidth: 130,
      flex: 1.3,
      valueFormatter: (params) => formatCurrency(params.value),
      type: "number",
    },
    {
      field: "rentPerSqft",
      headerName: "Rent /sqft",
      minWidth: 115,
      flex: 1.15,
      valueFormatter: (params) => formatCurrency(params.value),
      type: "number",
    },
    {
      field: "beds",
      headerName: "Beds",
      minWidth: 100,
      flex: 1,
      type: "number",
    },
    {
      field: "baths",
      headerName: "Baths",
      minWidth: 100,
      flex: 1,
      type: "number",
    },
    {
      headerName: "Actions",
      width: 100,
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit Unit"
          onClick={() => handleEditSingleUnitClick(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<NoteAdd />}
          label="Add Inspection Record"
          onClick={() => handleInspectUnitClick(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Visibility />}
          label="View Inspection Records"
          onClick={() => handleViewUnitInspectionsClick(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          sx={{ color: "red" }}
          icon={<Delete sx={{ color: "red" }} />}
          label="Delete Unit"
          onClick={() => handleDeleteUnitModal(params.row)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Permit
      roles="BLDIR,BLDMG,CS"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role is not permitted to view this page. <br /> Please
            contact your system admin if you feel this is an error.
          </>
        ),
      }}
    >
      <MainContainer>
        {propertyModal && (
          <PropertyModal
            handlePropertyModalClose={handlePropertyModalClose}
            propertyModal={propertyModal}
            selectedProperty={selectedProperty}
            subscriptionId={subscription.id}
            subscriptionOp={false}
          />
        )}

        {loading ? (
          <ResponsiveRow
            sx={{
              flexDirection: "column",
              flexWrap: "nowrap",
              height: "60vh",
              width: "100%",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              sx={{
                fontSize: "1.2rem",
                color: kodi_theme.palette.primary.dark,
                height: "auto",
                margin: "10px auto",
              }}
            />
            <Typography
              variant="body2"
              color="primary.dark"
              textAlign="center"
              sx={{ width: "100%", height: "auto", margin: "10px auto" }}
            >
              Loading, please wait...
            </Typography>
          </ResponsiveRow>
        ) : (
          !isEmptyObject(selectedProperty) && (
            <>
              <TopBarContainer
                container
                sx={{
                  width: { xs: "100%", lg: "80%" },
                  boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
                }}
              >
                <ResponsiveRow
                  sx={{
                    width: { xs: "100%", lg: "20%" },
                    justifyContent: { xs: "center", lg: "flex-start" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 800,
                      margin: "auto",
                      width: "100%",
                      textAlign: "start",
                      padding: "20px",
                    }}
                  >
                    {selectedProperty.name}
                  </Typography>
                </ResponsiveRow>
                <ResponsiveRow
                  // item
                  sx={{
                    flexWrap: { xs: "wrap", lg: "nowrap" },
                    justifyContent: { xs: "center", lg: "flex-end" },
                    width: { xs: "100%", lg: "80%" },
                    padding: 0,
                  }}
                >
                  {/* <Permit roles="BLDIR,SYSAD"></Permit> */}
                  <SplitButton buttons={propertyButtons} />
                  <SplitButton buttons={unitsButtons} />
                  <SplitButton buttons={inspectionButtons} />
                  {/*<TopBarContainedBrandButton*/}
                  {/*  sx={{ width: { xs: "100%", lg: "200px" }, padding: "10px" }}*/}
                  {/*  onClick={() => setBulkModal(true)}*/}
                  {/*>*/}
                  {/*  Bulk Upload*/}
                  {/*</TopBarContainedBrandButton>*/}
                </ResponsiveRow>
              </TopBarContainer>

              <ResponsiveRow
                sx={{
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: "auto",
                  margin: "10px auto",
                }}
              >
                <ResponsiveRow
                  container
                  sx={{
                    width: { xs: "100%", md: "90%" },
                    minHeight: "240px",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
                    margin: "20px auto",
                    padding: "20px",
                  }}
                >
                  <ResponsiveRow
                    sx={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      margin: "auto 20px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 800,
                        fontSize: "18px",
                      }}
                    >
                      Property Information
                    </Typography>
                  </ResponsiveRow>

                  <ResponsiveRow
                    sx={{
                      flexDirection: { lg: "row", xs: "column" },
                      flexWrap: "nowrap",
                      justifyContent: "space-around",
                      alignItems: "flex-start",
                      width: "100%",
                      height: "auto",
                      margin: "0 20px 20px 20px",
                      padding: 0,
                    }}
                  >
                    <ResponsiveRow
                      sx={{
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: { lg: "30%", md: "100%", xs: "100%" },
                        height: "100%",
                        padding: "20px",
                        margin: "10px 5px",
                        backgroundColor: kodi_theme.palette.grey[200],
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 800,
                          fontSize: "16px",
                          textAlign: "start",
                          color: "#037171",
                          marginBottom: "20px",
                        }}
                      >
                        Basic Information
                      </Typography>

                      <ResponsiveRow
                        sx={{
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: { xs: "100%", lg: "90%" },
                          height: "auto",
                          margin: "10px auto",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            textAlign: "start",
                            width: { xs: "50%", md: "50%" },
                          }}
                        >
                          Name
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#037171",
                            textAlign: "end",
                          }}
                        >
                          {selectedProperty?.name}
                        </Typography>
                      </ResponsiveRow>
                      <ResponsiveRow
                        sx={{
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          width: { xs: "100%", lg: "90%" },
                          height: "auto",
                          margin: "10px auto",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            textAlign: "start",
                            width: { xs: "50%", md: "100%" },
                          }}
                        >
                          Number of Units
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#037171",
                            textAlign: "end",
                          }}
                        >
                          {selectedProperty.totalUnits}
                        </Typography>
                      </ResponsiveRow>
                      <ResponsiveRow
                        sx={{
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          width: { xs: "100%", lg: "90%" },
                          height: "auto",
                          margin: "10px auto",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            textAlign: "start",
                            width: { xs: "50%", md: "100%" },
                          }}
                        >
                          Occupancy (%)
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#037171",
                            textAlign: "end",
                          }}
                        >
                          {selectedProperty.occupancy}
                        </Typography>
                      </ResponsiveRow>
                    </ResponsiveRow>
                    <ResponsiveRow
                      sx={{
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        width: { lg: "70%", md: "100%", xs: "100%" },
                        height: "100%",
                        padding: "20px",
                        margin: "10px 5px",
                        backgroundColor: kodi_theme.palette.grey[200],
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        color="primary.dark"
                        fontWeight={800}
                        textAlign="start"
                        sx={{
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      >
                        Payment Information
                      </Typography>
                      <Alert
                        severity={"primary"}
                        sx={{
                          textAlign: "start",
                          backgroundColor: kodi_theme.palette.common.white,
                          color: kodi_theme.palette.primary.dark,
                          display: { xs: "none", lg: "inherit" },
                        }}
                      >
                        This payment information will be sent out on the
                        invoices so that customers can make payments using the
                        appropriate service. <br />
                        <br />
                        <Permit services="HOA">
                          When pay to invoice/Charge is turned on, payment
                          received would be used to offset the invoice/Charge in
                          reference. <br />
                          When hide balance brought forward is turned on,
                          previously unpaid sum would be hidden on the
                          invoice/Charge.
                        </Permit>
                      </Alert>
                      {selectedProperty?.facilityPayInfo?.useMpesa && (
                        <ResponsiveRow
                          sx={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: { xs: "100%", lg: "90%" },
                            height: "auto",
                            margin: "10px auto",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              textAlign: "start",
                              width: { xs: "50%", md: "50%" },
                            }}
                          >
                            MPESA Paybill
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#037171",
                              textAlign: "end",
                            }}
                          >
                            {selectedProperty?.facilityPayInfo?.mpesaPaybill}
                          </Typography>
                        </ResponsiveRow>
                      )}

                      {selectedProperty?.facilityPayInfo?.useMpesa && (
                        <ResponsiveRow
                          sx={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: { xs: "100%", lg: "90%" },
                            margin: "10px auto",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              textAlign: "start",
                              width: { xs: "50%", md: "100%" },
                            }}
                          >
                            MPESA Shortcode
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#037171",
                              textAlign: "end",
                            }}
                          >
                            {
                              selectedProperty?.facilityPayInfo
                                ?.mpesaBusinessShortcode
                            }
                          </Typography>
                        </ResponsiveRow>
                      )}

                      {selectedProperty?.facilityPayInfo?.useBankAccount && (
                        <ResponsiveRow
                          sx={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: { xs: "100%", lg: "90%" },
                            margin: "10px auto",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              textAlign: "start",
                              width: { xs: "50%", md: "100%" },
                            }}
                          >
                            Bank Account Name
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#037171",
                              textAlign: "end",
                            }}
                          >
                            {selectedProperty?.facilityPayInfo?.bankAccountName}
                          </Typography>
                        </ResponsiveRow>
                      )}
                      {selectedProperty?.facilityPayInfo?.useBankAccount && (
                        <ResponsiveRow
                          sx={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: { xs: "100%", lg: "90%" },
                            height: "auto",
                            margin: "10px auto",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              textAlign: "start",
                              width: { xs: "50%", md: "50%" },
                            }}
                          >
                            Bank Branch
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#037171",
                              textAlign: "end",
                            }}
                          >
                            {
                              selectedProperty?.facilityPayInfo
                                ?.bankAccountBranch
                            }
                          </Typography>
                        </ResponsiveRow>
                      )}
                      {selectedProperty?.facilityPayInfo?.useBankAccount && (
                        <ResponsiveRow
                          sx={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: { xs: "100%", lg: "90%" },
                            margin: "10px auto",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              textAlign: "start",
                              width: { xs: "50%", md: "100%" },
                            }}
                          >
                            Account No
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#037171",
                              textAlign: "end",
                            }}
                          >
                            {
                              selectedProperty?.facilityPayInfo
                                ?.bankAccountNumber
                            }
                          </Typography>
                        </ResponsiveRow>
                      )}
                    </ResponsiveRow>
                  </ResponsiveRow>
                  <Permit roles={"SYSAD,BLDIR"}>
                    <Typography
                      fontWeight={800}
                      textAlign="start"
                      variant="h5"
                      color="primary.dark"
                      sx={{
                        margin: "10px 30px 5px 30px",
                      }}
                    >
                      Services
                    </Typography>
                    <ResponsiveRow
                      sx={{
                        width: "100%",
                        height: "200px",
                        overflowX: "hidden",
                        overflowY: "auto",
                        margin: "0 20px",
                        padding: { xs: 0, lg: "10px" },
                      }}
                    >
                      {selectedProperty.subscriptionServices?.length > 0 &&
                        selectedProperty.subscriptionServices.map(
                          (service, i) => (
                            <PropertySubscriptionServiceItem
                              key={i}
                              data={service}
                            />
                          )
                        )}
                    </ResponsiveRow>
                  </Permit>
                </ResponsiveRow>
              </ResponsiveRow>
              {selectionModel.length > 1 && (
                <Permit roles="BLDIR,SYSAD" services="PREMIUM,HOA,CORE,LEAN">
                  <ResponsiveRow
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "auto",
                      margin: { xs: "20px 10px", lg: "40px auto 20px auto" },
                      padding: "10px 20px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      boxShadow: "1px 1px 15px 0 rgba(0,0,0,0.5)",
                    }}
                  >
                    <ResponsiveRow
                      sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: { xs: "100%", lg: "50%" },
                        height: "100%",
                        padding: { xs: "10px 0", lg: "auto 10px" },
                      }}
                    >
                      {" "}
                      <Typography variant="h5" fontWeight={700}>
                        Bulk Units Operations
                      </Typography>
                    </ResponsiveRow>
                    <ResponsiveRow
                      sx={{
                        flexDirection: { xs: "column", lg: "row" },
                        flexWrap: "nowrap",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: 0,
                        width: { xs: "100%", lg: "50%" },
                        height: "100%",
                        margin: "auto",
                      }}
                    >
                      <TopBarContainedBrandButton
                        sx={{
                          width: { xs: "100%", lg: "200px", height: "auto" },
                        }}
                      >
                        Edit {selectionModel.length} Units
                      </TopBarContainedBrandButton>
                    </ResponsiveRow>
                  </ResponsiveRow>
                </Permit>
              )}

              <Permit services="PREMIUM, HOA, CORE, LEAN, MAINT">
                {facilityUnits.length > 0 && (
                  <MuiDataGrid
                    dataGridColumns={unitColumns}
                    dataGridRows={facilityUnits}
                    serverPagination={false}
                    handleSelectedRows={setSelectionModel}
                    currentSelectionModel={selectionModel}
                    loading={loading}
                    height="60vh"
                    totalRows={facilityUnits.length}
                  />
                )}
              </Permit>
            </>
          )
        )}

        <BulkUpload
          showBulkUploadModal={BulkModal}
          handleBulkUploadClose={() => setBulkModal(false)}
        />

        {/*INSPECT MODAL*/}
        {task === "Inspect" && (
          <Inspections
            openModal={InspectModal}
            closeModal={() => setInspectModal(false)}
            contract={contract}
            inspectionItem={inspect}
          />
        )}

        <ViewInspections
          openModal={ViewModal}
          closeModal={() => {
            setViewModal(false);
            setInspectionRecords([]);
          }}
          contract={contract}
          inspectionItem={inspect}
          inspectionRecords={inspectionRecords}
        />
      </MainContainer>

      {unitModal && (
        <UnitModal
          setUnitModal={setUnitModal}
          unitModal={unitModal}
          unit={selectedUnit}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          handleConfirmationClose={handleConfirmationClose}
          title={titleText}
          severity={severityText}
          body={bodyText}
          positiveText={positiveText}
          negativeText={negativeText}
          positiveAction={handleDeleteUnit}
          negativeAction={handleConfirmationClose}
        />
      )}
    </Permit>
  );
}
