import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import Permit from "../../../../util/permit";
import {
  isArray,
  isEmptyArray,
  isEmptyObject,
  isEmptyString,
  isNullUndefined,
} from "../../../../util/util";
import { useEffect, useState } from "react";
import {
  getAllSubscriptionServices,
  getCountries,
  getIdTypes,
  getRoles,
  getSubscriptionBankAccounts,
} from "../../../../services/list.service";
import {
  issueErrorMessage,
  issueResponseMessage,
  issueSuccessMessage,
  issueWarnMessage,
} from "../../../../actions/message";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import { Cancel } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { saveProperty } from "../../../../services/property.service";
import { FileUpload } from "../../../inputs/fileUpload";
import { downloadBulkUploadTemplate } from "../../../../services/shared.service";

/**
 * This component is shared between the property page and the subscription
 * page. When on the subscription page, there shall be an option to add users
 * and services. Units shall only be added on the property page.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PropertyModal(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [lrNumber, setLrNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [countryCd, setCountryCd] = useState("");
  const [mpesaPaybill, setMpesaPaybill] = useState("");
  const [mpesaBusinessShortcode, setMpesaBusinessShortcode] = useState("");
  const [mpesaKey, setMpesaKey] = useState("");
  const [mpesaSecret, setMpesaSecret] = useState("");
  const [mpesaPassKey, setMpesaPassKey] = useState("");
  const [bankAccountId, setBankAccountId] = useState("");
  const [workOrderRequest, setWorkOrderRequest] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [subscriptionBankAccounts, setSubscriptionBankAccounts] = useState([]);
  const [useBankAccount, setUseBankAccount] = useState(false);
  const [useMpesa, setUseMpesa] = useState(false);
  const [hideBalanceBroughtForward, setHideBalanceBroughtForward] = useState(false);
  const [payInvoice, setPayInvoice] = useState(false);
  const [woApprovalThreshold, setWoApprovalThreshold] = useState(false);
  const [approvalThresholdAmount, setApprovalThresholdAmount] = useState(0);
  const [users, setUsers] = useState([]);
  const [subscriptionServices, setSubscriptionServices] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [idTypeList, setIdTypeList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [bulkFile, setBulkFile] = useState(null);
  const [bulkFileProgress] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState("");

  useEffect(() => {
    getCountries().then((countryOptions) => setCountryOptions(countryOptions));
    getAllSubscriptionServices().then((services) => setServicesList(services));
    if (props.subscriptionOp) {
      getSubscriptionBankAccounts(props.subscriptionId).then(
        (subscriptionBankAccounts) =>
          setSubscriptionBankAccounts(subscriptionBankAccounts)
      );
    } else {
      getSubscriptionBankAccounts(null).then((subscriptionBankAccounts) =>
        setSubscriptionBankAccounts(subscriptionBankAccounts)
      );
    }
    if (props.subscriptionOp) {
      getIdTypes().then((idTypes) => setIdTypeList(idTypes));
      getRoles().then((roles) => setRoleList(roles));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subscriptionOp]);

  useEffect(() => {
    if (subscriptionServices.length === 0) {
      setSubscriptionType("");
    }
    for (let i = 0; i < subscriptionServices.length; i++) {
      if (isNullUndefined(subscriptionServices[i].serviceId)) {
        setSubscriptionType("");
      }
      const isHOA =
        servicesList.filter(function (s) {
          return (
            s.value === subscriptionServices[i].serviceId &&
            s.label.includes("Home Owners Association Service")
          );
        }).length > 0;

      const isResidential =
        servicesList.filter(function (s) {
          return (
            s.value === subscriptionServices[i].serviceId &&
            (s.label.includes("Premium Service") ||
              s.label.includes("Core Service") ||
              s.label.includes("Lean Service"))
          );
        }).length > 0;

      const isMaintenance =
        servicesList.filter(function (s) {
          return (
            s.value === subscriptionServices[i].serviceId &&
            s.label.includes("Maintenance")
          );
        }).length > 0;

      const isMeterBilling =
        servicesList.filter(function (s) {
          return (
            s.value === subscriptionServices[i].serviceId &&
            s.label.includes("Utility Service")
          );
        }).length > 0;

      if (isResidential) {
        setSubscriptionType("RES");
      }

      if (isMaintenance) {
        setSubscriptionType("MAINT");
      }

      if (isHOA) {
        setSubscriptionType("HOA");
      }

      if (!isResidential && subscriptionType !== "RES" && isMeterBilling) {
        let hasRes = false;
        for (let i = 0; i < subscriptionServices.length; i++) {
          if (
            servicesList.filter(function (s) {
              return (
                s.value === subscriptionServices[i].serviceId &&
                (s.label.includes("Premium Service") ||
                  s.label.includes("Core Service") ||
                  s.label.includes("Lean Service"))
              );
            }).length > 0
          ) {
            hasRes = true;
          }
        }
        if (!hasRes) {
          setSubscriptionType("MEB");
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionServices]);

  useEffect(() => {
    //load the passed in property
    if (!isEmptyObject(props.selectedProperty)) {
      setName(props.selectedProperty.name);
      setDescription(props.selectedProperty.description);
      setLatitude(props.selectedProperty.latitude);
      setLongitude(props.selectedProperty.longitude);
      setLrNumber(props.selectedProperty.lrNumber);
      setAddress1(props.selectedProperty.address.address1);
      setAddress2(props.selectedProperty.address.address2);
      setCity(props.selectedProperty.address.city);
      setCountryCd(props.selectedProperty.address.countryCd);
      setMpesaPaybill(props.selectedProperty.facilityPayInfo.mpesaPaybill);
      setMpesaBusinessShortcode(
        props.selectedProperty.facilityPayInfo.mpesaBusinessShortcode
      );
      setMpesaKey(props.selectedProperty.facilityPayInfo.mpesaKey);
      setUseMpesa(props.selectedProperty.facilityPayInfo.useMpesa);
      setPayInvoice(props.selectedProperty.facilityPayInfo.payInvoice);
      setUseBankAccount(props.selectedProperty.facilityPayInfo.useBankAccount);
      setMpesaSecret(props.selectedProperty.facilityPayInfo.mpesaSecret);
      setMpesaPassKey(props.selectedProperty.facilityPayInfo.mpesaPassKey);
      setBankAccountId(props.selectedProperty.facilityPayInfo.bankAccountId);
      setWorkOrderRequest(props.selectedProperty.workOrderRequest);
      setHideBalanceBroughtForward(
        props.selectedProperty.facilityPayInfo.hideBalanceBroughtForward
      );
      setSubscriptionServices(props.selectedProperty.subscriptionServices);
      if (!isEmptyString(props.selectedProperty.approvalThresholdAmount)) {
        setWoApprovalThreshold(true);
        setApprovalThresholdAmount(
          props.selectedProperty.approvalThresholdAmount
        );
      }
      if (!isNullUndefined(props.selectedProperty.workOrderRequest)) {
        setWorkOrderRequest(props.selectedProperty.workOrderRequest)
      }

      if (props.subscriptionOp) {
        setUsers(props.selectedProperty.users);
      }
    } else {
      if (subscriptionServices.length === 0) addSubscriptionServices();
      if (props.subscriptionOp) {
        //add the initial user and service rows
        if (users.length === 0) addUser();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedProperty, props.subscriptionOp]);

  const fileValueChanged = (event) => {
    setBulkFile(event.target.files[0]);
  };

  const validatePropertyForm = () => {
      if (isEmptyString(name)) {
        issueErrorMessage("The Property must have a name!!");
        return false;
      }
      if (isEmptyString(address1)) {
        issueErrorMessage("The Property must have an address!!");
        return false;
      }
      if (isEmptyString(countryCd)) {
        issueErrorMessage("Please select a country!!");
        return false;
      }
      if (isEmptyString(city)) {
        issueErrorMessage("The Property must have an city!!");
        return false;
      }
      if (woApprovalThreshold && approvalThresholdAmount <= 0) {
        issueErrorMessage("Please add work order approval threshold amount!");
        return false;
      }
      if (isEmptyObject(props.selectedProperty) && isNullUndefined(bulkFile)) {
        issueErrorMessage("You must add a bulk upload file with the property details.");
        return false;
      }
    return true;
  };

  const validateFacilityPayInfo = () => {
    if (useMpesa === false && useBankAccount === false) {
      issueWarnMessage(
        "Must select either Mpesa or Bank account and add details"
      );
      return false;
    }
    if (useMpesa) {
      // if paybill given then short code must be there & vice versa
      if (
        isEmptyString(mpesaPaybill) &&
        !isEmptyString(mpesaBusinessShortcode)
      ) {
        issueWarnMessage(
          "Must provide a paybill number when a shortcode is given"
        );
        return false;
      }
      if (
        !isEmptyString(mpesaPaybill) &&
        isEmptyString(mpesaBusinessShortcode)
      ) {
        issueWarnMessage(
          "Must provide a business short code when a paybill is already provided"
        );
        return false;
      }
      if (!isEmptyString(mpesaPaybill) && mpesaPaybill.length > 10) {
        issueWarnMessage("MPESA Paybill cannot be more than 10 characters");
        return false;
      }
      if (
        !isEmptyString(mpesaBusinessShortcode) &&
        mpesaBusinessShortcode.length > 10
      ) {
        issueWarnMessage(
          "MPESA Business Shortcode cannot be more than 10 characters"
        );
        return false;
      }
    }

    if (isNullUndefined(mpesaPassKey)) {
      setMpesaPassKey("");
    }
    if (isNullUndefined(mpesaKey)) {
      setMpesaKey("");
    }
    if (isNullUndefined(mpesaSecret)) {
      setMpesaSecret("");
    }

    if (useBankAccount) {
      if (isEmptyString(bankAccountId)) {
        issueWarnMessage(
          "Must selected a bank account when 'use a bank account' is checked"
        );
        return false;
      }
    }
    return true;
  };

  const usersValid = () => {
    if (isNullUndefined(users) || !isArray(users) || isEmptyArray(users)) {
      issueErrorMessage(
        "There must be at least be one user added to the Property"
      );
      return false;
    }
    for (let i = 0; i < users.length; i++) {
      if (isNullUndefined(users[i].name)) {
        issueErrorMessage(`Name is required for the user on row ${i + 1}`);
        return false;
      }
      if (!isNullUndefined(users[i].name)) {
        const namesArray = users && users[i].name.split(" ");
        if (namesArray.length <= 1) {
          issueErrorMessage(
            `Make sure you have provided First Name and Last Name for the user on row ${
              i + 1
            }`
          );
          return false;
        }
      }
      if (isNullUndefined(users[i].mobilePhone)) {
        issueErrorMessage(
          `Mobile phone is required for the user on row ${i + 1}`
        );
        return false;
      }
      if (isNullUndefined(users[i].email)) {
        issueErrorMessage(`Email is required for the user on row ${i + 1}`);
        return false;
      }
      if (isNullUndefined(users[i].idNumber)) {
        issueErrorMessage(`ID Number is required for the user on row ${i + 1}`);
        return false;
      }
      if (isNullUndefined(users[i].idTypeCd)) {
        issueErrorMessage(`ID Type is required for the user on row ${i + 1}`);
        return false;
      }
      if (isNullUndefined(users[i].roleCd)) {
        issueErrorMessage(`Role is required for the user on row ${i + 1}`);
        return false;
      }
    }
    return true;
  };

  const servicesValid = () => {
    if (subscriptionServices.length === 0) {
      issueErrorMessage(
        "There must at least be one service added to the Property"
      );
      return false;
    }
    for (let i = 0; i < subscriptionServices.length; i++) {
      if (isNullUndefined(subscriptionServices[i].serviceId)) {
        issueErrorMessage(
          `A Service selection is required for the service on row ${i + 1}`
        );
        return false;
      }
    }
    return true;
  };

  const submit = () => {
    if (validatePropertyForm()) {
      if (validateFacilityPayInfo()) {
        if (servicesValid()) {
          let sendData = {
            id: props.selectedProperty?.id,
            name : name,
            description : description,
            latitude : latitude,
            longitude : longitude,
            lrNumber : lrNumber,
            address : {
              id: props.selectedProperty?.address?.id ?? null,
              address1: address1,
              city: city,
              address2: address2,
              countryCd: countryCd,
            },
            dateCreated: props.selectedProperty?.dateCreated,
            workOrderRequest: workOrderRequest,
            workOrderApprovalThreshold: woApprovalThreshold,
            approvalThresholdAmount: approvalThresholdAmount,
            subscriptionOp: props.subscriptionOp,
            subscriptionId: props.subscriptionId,
            facilityPayInfo: {
              useBankAccount: useBankAccount,
              useMpesa: useMpesa,
              mpesaPaybill: mpesaPaybill,
              mpesaBusinessShortcode: mpesaBusinessShortcode,
              bankAccountId: bankAccountId,
              payInvoice: payInvoice,
              mpesaKey: mpesaKey,
              mpesaSecret: mpesaSecret,
              mpesaPassKey: mpesaPassKey,
              hideBalanceBroughtForward: hideBalanceBroughtForward,
            },
            subscriptionServices: subscriptionServices,
          };
          //if this is being done from the subscriptions page, then add in the user details
          if (props.subscriptionOp && usersValid()) {
            sendData.users = users;
            sendData.subscriptionId = props.subscriptionId;
            sendData.subscriptionOp = props.subscriptionOp;
          }
          //now build the final payload
          let data = new FormData();
          data.append("file", bulkFile);
          data.append("property", JSON.stringify(sendData));
          saveProperty(data).then((response) => {
            issueResponseMessage(response.data);
            props.handlePropertyModalClose(response?.facility?.id ?? null);
          });
        }
      }
    }
  };

  const addUser = () => {
    let usersArray = [...users];
    usersArray.push({
      id: null,
      name: null,
      mobilePhone: null,
      email: null,
      idNumber: null,
      idTypeCd: null,
      roleCd: null,
    });
    setUsers(usersArray);
  };

  const addSubscriptionServices = () => {
    let subscriptionServicesArray = [...subscriptionServices];
    subscriptionServicesArray.push({
      id: null,
      serviceId: null,
      discount: null,
      trialEndDate: null,
      mpesaBillingStartDate: null,
    });
    setSubscriptionServices(subscriptionServicesArray);
  };

  const getTemplate = () => {
    downloadBulkUploadTemplate(subscriptionType)
      .then(() => {
        // dispatch(
        //   setMessage({
        //     type: "success",
        //     message: "Template downloaded",
        //   })
        // );
        issueSuccessMessage("Template downloaded")
      })
      .catch((error) => {
        // dispatch(
        //   setMessage({
        //     type: "error",
        //     message: "Error occurred while getting the template!",
        //   })
        // );
        issueErrorMessage( "Error occurred while getting the template!")
      });
  };

  const userNameChanged = (event, index) => {
    let value = event.target.value;
    let usersArray = [...users]; // copying the old items array
    usersArray[index].name = value;
    setUsers(usersArray);
  };
  const userPhoneChanged = (event, index) => {
    let value = event.target.value;
    let usersArray = [...users]; // copying the old items array
    usersArray[index].mobilePhone = value;
    setUsers(usersArray);
  };
  const userEmailChanged = (event, index) => {
    let value = event.target.value;
    let usersArray = [...users]; // copying the old items array
    usersArray[index].email = value;
    setUsers(usersArray);
  };
  const userIdChanged = (event, index) => {
    let value = event.target.value;
    let usersArray = [...users]; // copying the old items array

    if (!isNaN(parseInt(value))) {
      //checks if value is a number
      usersArray[index].idNumber = value;
    }
    setUsers(usersArray);
  };
  const userIdTypeChanged = (event, index) => {
    let value = event.target.value;
    let usersArray = [...users]; // copying the old items array
    usersArray[index].idTypeCd = value;
    setUsers(usersArray);
  };
  const userRoleChanged = (event, index) => {
    let value = event.target.value;
    let usersArray = [...users]; // copying the old items array
    usersArray[index].roleCd = value;
    setUsers(usersArray);
  };
  const removeUser = (index) => {
    let usersArray = [...users]; // copying the old items array
    usersArray.splice(index, 1);
    setUsers(usersArray);
  };

  let userRows = [];
  users &&
    users.length > 0 &&
    (userRows = users.map((user, i) => {
      return (
        <TableRow key={i}>
          <TableCell sx={{ width: "15%" }}>
            <TextField
              placeholder={"Name"}
              sx={{ width: "90%" }}
              id={"name" + i}
              onChange={(event) => {
                userNameChanged(event, i);
              }}
              value={user.name}
            />
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            <TextField
              placeholder={"Phone"}
              type="string"
              sx={{ width: "90%" }}
              id={"mobilePhone" + i}
              onChange={(event) => {
                userPhoneChanged(event, i);
              }}
              value={user.mobilePhone}
            />
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            <TextField
              placeholder={"Email"}
              sx={{ width: "90%" }}
              id={"email" + i}
              onChange={(event) => {
                userEmailChanged(event, i);
              }}
              value={user.email}
            />
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            <TextField
              placeholder={"ID Number"}
              type="string"
              sx={{ width: "90%" }}
              id={"idNumber" + i}
              onChange={(event) => {
                userIdChanged(event, i);
              }}
              value={user.idNumber}
            />
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            <Select
              sx={{ width: "90%" }}
              id={"idType" + i}
              name={"idType" + i}
              defaultValue=""
              placeholder="ID Type"
              value={user.idTypeCd}
              onChange={(event) => userIdTypeChanged(event, i)}
            >
              {idTypeList !== undefined && idTypeList.length > 0 ? (
                idTypeList.map((item, j) => (
                  <MenuItem key={j} value={item.value}>
                    {" "}
                    {item.label}{" "}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            <Select
              sx={{ width: "90%" }}
              id={"role" + i}
              name={"role" + i}
              defaultValue=""
              placeholder="Role"
              value={user.roleCd}
              onChange={(event) => userRoleChanged(event, i)}
            >
              {roleList !== undefined && roleList.length > 0 ? (
                roleList.map((item, k) => (
                  <MenuItem key={k} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </TableCell>
          <TableCell sx={{ width: "10%" }}>
            <Button
              variant="contained"
              type="button"
              sx={{ marginLeft: "10px" }}
              onClick={() => removeUser(i)}
              color="error"
            >
              <Cancel />
            </Button>
          </TableCell>
        </TableRow>
      );
    }));

  const serviceChanged = (event, index) => {
    let value = event.target.value;
    if (index === 0 && subscriptionServices.length > 1) {
      let subscriptionServicesArray = [...subscriptionServices]; // copying the old items array
      subscriptionServicesArray[index].serviceId = value;
      setSubscriptionServices(
        subscriptionServicesArray.filter(function (ss) {
          return ss.serviceId === value;
        })
      );
    } else {
      let subscriptionServicesArray = [...subscriptionServices]; // copying the old items array
      subscriptionServicesArray[index].serviceId = value;
      setSubscriptionServices(subscriptionServicesArray);
    }
  };
  const discountChanged = (event, index) => {
    let value = event.target.value;
    let subscriptionServicesArray = [...subscriptionServices]; // copying the old items array
    subscriptionServicesArray[index].discount = value;
    setSubscriptionServices(subscriptionServicesArray);
  };
  const trialEndDateChanged = (value, index) => {
    // let value = event.target.value
    let subscriptionServicesArray = [...subscriptionServices]; // copying the old items array
    subscriptionServicesArray[index].trialEndDate = value;
    setSubscriptionServices(subscriptionServicesArray);
  };
  const mpesaBillingStartDateChanged = (value, index) => {
    // let value = event.target.value
    let subscriptionServicesArray = [...subscriptionServices]; // copying the old items array
    subscriptionServicesArray[index].mpesaBillingStartDate = value;
    setSubscriptionServices(subscriptionServicesArray);
  };

  const removeService = (index) => {
    let subscriptionServicesArray = [...subscriptionServices]; // copying the old items array
    subscriptionServicesArray.splice(index, 1);
    setSubscriptionServices(subscriptionServicesArray);
  };

  const disableChecker = (serviceId, index) => {
    let residentialServices = [];
    let premiumService;
    let hoaServices = [];
    let maintenanceServices = [];
    let bulkWaterServices = [];
    let utilityServices = [];
    let mpesaServices = [];

    servicesList &&
      servicesList.length > 0 &&
      servicesList.forEach((service, i) => {
        if (service.label === "Utility Service") {
          utilityServices.push(service.value);
        }
        if (service.label === "Premium Service") {
          residentialServices.push(service.value);
          premiumService = service.value;
        }
        if (service.label === "Bulk Water Sales") {
          bulkWaterServices.push(service.value);
        }
        if (service.label === "Core Service") {
          residentialServices.push(service.value);
        }
        if (service.label === "Lean Service") {
          residentialServices.push(service.value);
        }
        if (service.label === "Home Owners Association Service") {
          hoaServices.push(service.value);
        }
        if (service.label === "MPESA 1% Service") {
          mpesaServices.push(service.value);
        }
        if (service.label === "MPESA 2% Service") {
          mpesaServices.push(service.value);
        }
        if (service.label === "Maintenance") {
          maintenanceServices.push(service.value);
        }
      });

    let disabled = false;
    let isResidential = false;
    let isPremium = false;
    let hasMpesa = false;
    let isHoa = false;
    let isMaint = false;
    let isBulkWater = false;
    let isUtility = false;
    if (index > 0) {
      for (let ss of subscriptionServices) {
        if (ss.serviceId === serviceId) {
          disabled = true;
        }

        //is residential
        for (let rs of residentialServices) {
          if (ss.serviceId === rs) {
            isResidential = true;
          }
        }

        //is Hoa
        for (let hoa of hoaServices) {
          if (ss.serviceId === hoa) {
            isHoa = true;
          }
        }

        //is Utility
        for (let util of utilityServices) {
          if (ss.serviceId === util) {
            isUtility = true;
          }
        }

        //is Hoa
        for (let maint of maintenanceServices) {
          if (ss.serviceId === maint) {
            isMaint = true;
          }
        }

        //is Bulk water
        for (let blk of bulkWaterServices) {
          if (ss.serviceId === blk) {
            isBulkWater = true;
          }
        }

        //has mpesa
        for (let m of mpesaServices) {
          if (ss.serviceId === m) {
            hasMpesa = true;
          }
        }

        //is premium
        if (premiumService === ss.serviceId) {
          isPremium = true;
        }
      }
    }

    if (isUtility && serviceId === premiumService) {
      // if it's utility remove premium services
      disabled = true;
    }

    if (
      isResidential &&
      hoaServices.filter((hoa) => serviceId === hoa).length > 0
    ) {
      // if it's residential remove hoa
      disabled = true;
    }

    if (
      isResidential &&
      bulkWaterServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's residential remove bulk water
      disabled = true;
    }

    if (
      isResidential &&
      maintenanceServices.filter((maint) => serviceId === maint).length > 0
    ) {
      // if it's residential remove maintenance
      disabled = true;
    }

    if (
      isResidential &&
      isPremium &&
      utilityServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's residential and is premium remove utility
      disabled = true;
    }

    if (
      isResidential &&
      residentialServices.filter((res) => serviceId === res).length > 0
    ) {
      // if it's residential remove other residential services
      disabled = true;
    }

    if (
      isHoa &&
      residentialServices.filter((res) => serviceId === res).length > 0
    ) {
      // if it's hoa remove residential services
      disabled = true;
    }

    if (
      isHoa &&
      bulkWaterServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's hoa remove bulk water
      disabled = true;
    }

    if (
      isHoa &&
      maintenanceServices.filter((maint) => serviceId === maint).length > 0
    ) {
      // if it's hoa remove maintenance
      disabled = true;
    }

    if (
      isHoa &&
      utilityServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's hoa remove utility
      disabled = true;
    }

    if (
      hasMpesa &&
      mpesaServices.filter((mpes) => serviceId === mpes).length > 0
    ) {
      // if it has mpesa remove other mpesa services
      disabled = true;
    }

    if (
      isBulkWater &&
      residentialServices.filter((res) => serviceId === res).length > 0
    ) {
      // if it's blk remove residential services
      disabled = true;
    }

    if (
      isBulkWater &&
      hoaServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's blk remove hoa
      disabled = true;
    }

    if (
      isBulkWater &&
      maintenanceServices.filter((maint) => serviceId === maint).length > 0
    ) {
      // if it's blk remove maintenance
      disabled = true;
    }

    if (
      isBulkWater &&
      utilityServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's blk remove utility
      disabled = true;
    }

    if (
      isMaint &&
      residentialServices.filter((res) => serviceId === res).length > 0
    ) {
      // if it's Maint remove residential services
      disabled = true;
    }

    if (
      isMaint &&
      bulkWaterServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's Maint remove bulk water
      disabled = true;
    }

    if (
      isMaint &&
      hoaServices.filter((maint) => serviceId === maint).length > 0
    ) {
      // if it's Maint remove hoa
      disabled = true;
    }

    if (
      isMaint &&
      utilityServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's Maint remove utility
      disabled = true;
    }

    if (
      isUtility &&
      bulkWaterServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's utility remove bulk water
      disabled = true;
    }

    if (
      isUtility &&
      maintenanceServices.filter((maint) => serviceId === maint).length > 0
    ) {
      // if it's utility remove maintenance
      disabled = true;
    }

    if (
      isUtility &&
      hoaServices.filter((blk) => serviceId === blk).length > 0
    ) {
      // if it's utility remove hoa
      disabled = true;
    }

    return disabled;
  };

  let subscriptionServicesRows = [];
  subscriptionServices.length > 0 &&
    (subscriptionServicesRows = subscriptionServices.map(
      (subscriptionService, i) => {
        return (
          <TableRow key={i}>
            <TableCell sx={{ width: "22.5%" }}>
              <Select
                sx={{ width: "90%" }}
                id={"service" + i}
                name={"service" + i}
                defaultValue=""
                placeholder="Select type Interest/Charge"
                value={subscriptionService.serviceId}
                onChange={(event) => serviceChanged(event, i)}
              >
                {servicesList !== undefined && servicesList.length > 0 ? (
                  servicesList.map((item, j) => {
                    return (
                      <MenuItem
                        key={j}
                        disabled={disableChecker(item.value, i)}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            {props.subscriptionOp && (
              <TableCell sx={{ width: "22.5%" }}>
                <TextField
                  type="number"
                  placeholder={"Discount (%)"}
                  sx={{ width: "90%" }}
                  id={"discount" + i}
                  name={"discount" + i}
                  onChange={(event) => {
                    discountChanged(event, i);
                  }}
                  value={subscriptionService.discount}
                />
              </TableCell>
            )}
            {props.subscriptionOp && (
              <TableCell sx={{ width: "22.5%" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    sx={{ width: "90%" }}
                    format="DD-MM-YYYY"
                    id={"trialEndDate" + i}
                    value={dayjs(subscriptionService.trialEndDate)}
                    onChange={(event) =>
                      trialEndDateChanged(new Date(event._d), i)
                    }
                  />
                </LocalizationProvider>
              </TableCell>
            )}
            {props.subscriptionOp && (
              <TableCell sx={{ width: "22.5%" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    sx={{ width: "90%" }}
                    format="DD-MM-YYYY"
                    id={"mpesaBillingStartDate" + i}
                    value={dayjs(subscriptionService.mpesaBillingStartDate)}
                    onChange={(event) =>
                      mpesaBillingStartDateChanged(new Date(event._d), i)
                    }
                  />
                </LocalizationProvider>
              </TableCell>
            )}
            <TableCell sx={{ width: "10%" }}>
              <Button
                variant="contained"
                type="button"
                sx={{ marginLeft: "10px" }}
                onClick={() => removeService(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    ));

  return (
    <ModalContainerModule
      size="subscriptionAddProperty"
      accept={submit}
      cancel={() => {
        props.handlePropertyModalClose(props?.selectedProperty?.id ?? null);
      }}
      openModal={props.propertyModal}
      modalTitle={
        isEmptyObject(props.selectedProperty) ? "Add Property" : "Edit Property"
      }
      acceptButtonText={
        isEmptyObject(props.selectedProperty) ? "Create" : "Update"
      }
    >
      <ResponsiveRow container sx={{ width: "100%", height: "auto" }}>
        <ResponsiveRow container sx={{ width: "100%", height: "auto" }}>
          <Typography variant="h3" component="h2">
            Property Details
          </Typography>
          <Typography variant="h6" color="text.secondary">
            &nbsp;&nbsp;&nbsp;(Use the form below to enter the details
            of an individual property)
          </Typography>
        </ResponsiveRow>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Property Name *</InputLabel>
          <OutlinedInput
            id="complexName"
            label="Property Name *"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              setName(event.target.value);
            }}
            required={true}
            value={name}
            inputProps={
              <TextField
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
              />
            }
          />
        </FormControl>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Description</InputLabel>
          <OutlinedInput
            label="Description"
            id="description"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            value={description}
            inputProps={
              <TextField
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
              />
            }
          />
        </FormControl>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Address 1 *</InputLabel>
          <OutlinedInput
            id="address1"
            label="Address 1 *"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            required={true}
            onChange={(event) => {
              setAddress1(event.target.value);
            }}
            value={address1}
            inputProps={
              <TextField
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
              />
            }
          />
        </FormControl>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Address 2</InputLabel>
          <OutlinedInput
            label="Address 2"
            id="address2"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              setAddress2(event.target.value);
            }}
            value={address2}
            inputProps={
              <TextField
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
              />
            }
          />
        </FormControl>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>City *</InputLabel>
          <OutlinedInput
            label="City"
            id="city"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              setCity(event.target.value);
            }}
            required={true}
            value={city}
            inputProps={
              <TextField
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
              />
            }
          />
        </FormControl>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Country *</InputLabel>
          <Select
            label="Country *"
            sx={{ width: { xs: "100%", md: "90%" } }}
            id={"country"}
            name={"country"}
            required
            defaultValue=""
            value={countryCd}
            onChange={(event) => setCountryCd(event.target.value)}
          >
            {countryOptions !== undefined && countryOptions.length > 0 ? (
              countryOptions.map((country, i) => (
                <MenuItem key={i} value={country.value}>
                  {country.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Latitude</InputLabel>
          <OutlinedInput
            label="Latitude"
            id="latitude"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => setLatitude(event.target.value)}
            value={latitude}
            inputProps={
              <TextField
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
              />
            }
          />
        </FormControl>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Longitude</InputLabel>
          <OutlinedInput
            label="Longitude"
            id="longitude"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => setLongitude(event.target.value)}
            value={longitude}
            inputProps={
              <TextField
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
              />
            }
          />
        </FormControl>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Lr Number</InputLabel>
          <OutlinedInput
            label="LR Number"
            id="lrNumber"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => setLrNumber(event.target.value)}
            value={lrNumber}
            inputProps={
              <TextField
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
              />
            }
          />
        </FormControl>
        <br />
      </ResponsiveRow>

      {isEmptyObject(props.selectedProperty) && (
        <>
          <Divider
            sx={{
              width: "100%",
              height: "auto",
              margin: "auto",
              padding: "10px",
            }}
          >
            <Typography variant="h3" component="h2">
              THEN
            </Typography>
          </Divider>
          <ResponsiveRow
            sx={{
              width: "100%",
              height: "auto",
              margin: "auto",
              padding: "10px",
            }}
          >
            <ResponsiveRow container sx={{ width: "100%", height: "auto" }}>
              <Typography variant="h3" component="h2">
                Bulk Upload
              </Typography>
              <Typography variant="h6" color="text.secondary">
                &nbsp;&nbsp;&nbsp;(Use the bulk upload file to add details about your units, meters and residents)
              </Typography>
            </ResponsiveRow>
            <ResponsiveRow sx={{ width: "49%", height: "auto" }}>
              <Alert
                sx={{ width: "100%", marginBottom: "20px" }}
                severity="info"
              >
                <AlertTitle>
                  Upload the Excel file with the details of your Properties.
                </AlertTitle>
                If you are unsure of the format to use in your document, please
                select the subscription type below and then click on the
                "Download Template" to obtain an example document.
              </Alert>
            </ResponsiveRow>
            <ResponsiveRow
              container
              sx={{
                width: "50%",
                height: "auto",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
                <FormLabel>Attach Excel File</FormLabel>
                <FileUpload
                  id={"bulkFileInput"}
                  width="95%"
                  currentFile={bulkFile}
                  progress={bulkFileProgress}
                  onChange={fileValueChanged}
                  accept={
                    ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  }
                />
              </FormControl>
            </ResponsiveRow>
          </ResponsiveRow>
        </>
      )}

      <Divider
        sx={{
          width: "100%",
          height: "auto",
          margin: "auto",
          padding: "10px",
        }}
      >
        {/*<Chip label="Additional Property Details" />*/}
        <Alert
          icon={<InfoIcon fontSize="inherit" />}
          severity="success"
          sx={{
            width: "100%",
            border: "3px #037171 solid",
          }}
        >
          <AlertTitle>Additional Property Details</AlertTitle>
          The details selected below shall be applied to the property or the
          properties uploaded
        </Alert>
      </Divider>
      <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
        <Alert severity="info" sx={{ width: "49%" }}>
          <AlertTitle>Property Payment Information and Settings</AlertTitle>
          This payment information will be sent out on the invoices so that
          customers can make payments using the appropriate service. Leave it
          blank if you want no information to appear on the invoice.
          <Permit services="HOA">
            <br />
            When pay to invoice/Charge is turned on, payment received would be
            used to offset the invoice/Charge in reference. <br />
            When hide balance brought forward is turned on, previously unpaid
            sum would be hidden on the invoice/Charge.
          </Permit>
        </Alert>

        <br />

        <ResponsiveRow sx={{ width: "100%" }}>
          <FormControlLabel
            label="Use M-PESA"
            control={
              <Checkbox
                checked={useMpesa}
                value={"useMpesa"}
                onChange={(event) => {
                  setUseMpesa(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
          <Permit services="CORE,HOA,LEAN,PREMIUM,METER,MAINT">
          <FormControlLabel
            label="Use Bank Account"
            control={
              <Checkbox
                checked={useBankAccount}
                value={"useBankAccount"}
                onChange={(event) => {
                  setUseBankAccount(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
          </Permit>
          <Permit roles={"BLDIR"} services="CORE,HOA,LEAN,PREMIUM,METER,MAINT">
            <FormControlLabel
                label="Allow tenant work order requests?"
                control={
                  <Switch
                      checked={workOrderRequest}
                      onChange={(event) => {
                        setWorkOrderRequest(event.target.checked)
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                  />
                }
            />
          </Permit>

          <Permit roles={"BLDIR"} services="CORE,HOA,LEAN,PREMIUM,METER,MAINT">
            <FormControlLabel
              label="Work Order Approval Threshold"
              control={
                <Switch
                  checked={woApprovalThreshold}
                  onChange={(event) => {
                    setWoApprovalThreshold(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </Permit>
          <br />
        </ResponsiveRow>

        <br />

        <Permit services="HOA">
          <ResponsiveRow sx={{ width: "100%" }}>
            <FormControlLabel
              label="Hide Balance Brought Forward"
              control={
                <Switch
                  checked={hideBalanceBroughtForward}
                  onChange={(event) => {
                    setHideBalanceBroughtForward(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
            <FormControlLabel
              label="Pay Invoice/Charge"
              control={
                <Switch
                  checked={payInvoice}
                  onChange={(event) => {
                    setPayInvoice(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
            <br />
          </ResponsiveRow>
        </Permit>

        {woApprovalThreshold && (
            <ResponsiveRow container sx={{ width: "100%" }}>
              <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "30%" },
                    marginBottom: "10px",
                  }}
              >
                <InputLabel>Work Order Approval Threshold (KES)</InputLabel>
                <OutlinedInput
                    label="Work Order Approval Threshold (KES)"
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    type="string"
                    id="threshold"
                    required={woApprovalThreshold && true}
                    color="secondary"
                    value={approvalThresholdAmount}
                    onChange={(event) => {
                      setApprovalThresholdAmount(event.target.value);
                    }}
                    inputProps={
                      <TextField
                          sx={{
                            width: { xs: "100%", md: "90%" },
                          }}
                      />
                    }
                />
              </FormControl>
            </ResponsiveRow>
        )}

        <ResponsiveRow sx={{ width: "100%" }}>
          {useBankAccount ? (
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Select Bank Account</InputLabel>
              <Select
                label="Select Bank Account"
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"bankdetails"}
                defaultValue=""
                required={useBankAccount && true}
                name={"bankdetails"}
                value={bankAccountId}
                onChange={(event) => setBankAccountId(event.target.value)}
              >
                {subscriptionBankAccounts !== undefined &&
                subscriptionBankAccounts.length > 0 ? (
                  subscriptionBankAccounts.map((bankAcc, i) => (
                    <MenuItem key={i} value={bankAcc.value}>
                      {bankAcc.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          ) : (
            ""
          )}
          {useMpesa ? (
            <ResponsiveRow container sx={{ width: "100%" }}>
              <Grid item sx={{ width: "100%", marginBottom: "10px" }}>
                <Permit services="CORE,HOA,LEAN,PREMIUM,METER,MAINT">
                <Alert severity="warning">
                  If you want to use M-PESA and don't provide a Paybill &
                  Business Shortcode, you will be defaulted to using the
                  Smartkodi paybill
                </Alert>
                </Permit>
                <Permit services="BLWTR">
                  <Alert severity="warning">
                    M-PESA Till number and short code is request to enable payment through buy goods
                  </Alert>
                </Permit>
              </Grid>
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  marginBottom: "10px",
                }}
              >
                {
                  !isNullUndefined(props.selectedProperty) &&  isNullUndefined(props.selectedProperty.id) && <InputLabel>M-PESA Paybill</InputLabel>
                }
                <Permit services="CORE,HOA,LEAN,PREMIUM,METER,MAINT">
                  <InputLabel>M-PESA Paybill</InputLabel>
                </Permit>
                <Permit services="BLWTR">
                  <InputLabel>M-PESA Till</InputLabel>
                </Permit>
                <OutlinedInput
                  label="M-PESA Paybill"
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  type="paybill"
                  id="paybill"
                  required={useMpesa && true}
                  color="secondary"
                  value={mpesaPaybill}
                  onChange={(event) => {
                    setMpesaPaybill(event.target.value);
                  }}
                  inputProps={
                    <TextField
                      sx={{
                        width: { xs: "100%", md: "90%" },
                      }}
                    />
                  }
                />
              </FormControl>
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>M-PESA Business Code</InputLabel>
                <OutlinedInput
                  label="M-PESA Business Code"
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  type="businessCode"
                  id="businessCode"
                  color="secondary"
                  required={useMpesa && true}
                  value={mpesaBusinessShortcode}
                  onChange={(event) => {
                    setMpesaBusinessShortcode(event.target.value);
                  }}
                  inputProps={
                    <TextField
                      sx={{
                        width: { xs: "100%", md: "90%" },
                      }}
                    />
                  }
                />
              </FormControl>
              <Permit roles={"SYSAD"}>
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>M-PESA Key</InputLabel>
                  <OutlinedInput
                    label="M-PESA Key"
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    type="key"
                    id="key"
                    color="secondary"
                    value={mpesaKey}
                    onChange={(event) => {
                      setMpesaKey(event.target.value);
                    }}
                    inputProps={
                      <TextField
                        sx={{
                          width: { xs: "100%", md: "90%" },
                        }}
                      />
                    }
                  />
                </FormControl>
              </Permit>
              <Permit roles={"SYSAD"}>
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>M-PESA Secret</InputLabel>
                  <OutlinedInput
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    type="secret"
                    id="secret"
                    label="M-PESA Secret"
                    color="secondary"
                    value={mpesaSecret}
                    onChange={(event) => {
                      setMpesaSecret(event.target.value);
                    }}
                    inputProps={
                      <TextField
                        sx={{
                          width: { xs: "100%", md: "90%" },
                        }}
                      />
                    }
                  />
                </FormControl>
              </Permit>
              <Permit roles={"SYSAD"}>
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>
                    M-PESA Pass Key (for STK and Transaction Query)
                  </InputLabel>
                  <OutlinedInput
                    sx={{ width: "90%" }}
                    type="passKey"
                    label="M-PESA Pass Key (for STK and Transaction Query)"
                    id="passKey"
                    color="secondary"
                    value={mpesaPassKey}
                    onChange={(event) => {
                      setMpesaPassKey(event.target.value);
                    }}
                    inputProps={
                      <TextField
                        sx={{
                          width: { xs: "100%", md: "90%" },
                        }}
                      />
                    }
                  />
                </FormControl>
              </Permit>
            </ResponsiveRow>
          ) : (
            ""
          )}
        </ResponsiveRow>
      </ResponsiveRow>

      {props.subscriptionOp && (
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            margin: "auto",
            padding: "10px",
          }}
        >
          <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
            <ResponsiveRow sx={{ width: { xs: "100%", lg: "66.6%" } }}>
              <Typography
                sx={{ marginBottom: "5px" }}
                id="modal-modal-title"
                variant="h5"
                component="h4"
              >
                Add User(s) (all fields are required)
              </Typography>
            </ResponsiveRow>
          </ResponsiveRow>
          <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "15%" }}>Name</TableCell>
                    <TableCell sx={{ width: "15%" }}>Phone</TableCell>
                    <TableCell sx={{ width: "15%" }}>Email</TableCell>
                    <TableCell sx={{ width: "15%" }}>ID Number</TableCell>
                    <TableCell sx={{ width: "15%" }}>ID Type</TableCell>
                    <TableCell sx={{ width: "15%" }}>Role</TableCell>
                    <TableCell sx={{ width: "10%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{userRows}</TableBody>
              </Table>
            </TableContainer>
          </ResponsiveRow>
          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              width: "100%",
            }}
          >
            <ModalAcceptButton
              // type="submit"
              onClick={() => addUser()}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Add User
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}
      <Permit roles={"SYSAD,BLDIR"}>
        <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
          <ResponsiveRow sx={{ width: { xs: "100%", lg: "66.6%" } }}>
            <Typography
              sx={{ marginBottom: "5px" }}
              id="modal-modal-title"
              variant="h5"
              component="h4"
            >
              Add Service(s) (all fields are required)
            </Typography>
          </ResponsiveRow>
        </ResponsiveRow>
        <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
          <TableContainer>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "22.5%" }}>Service</TableCell>
                  {props.subscriptionOp && (
                    <TableCell sx={{ width: "22.5%" }}>Discount (%)</TableCell>
                  )}
                  {props.subscriptionOp && (
                    <TableCell sx={{ width: "22.5%" }}>
                      Trial End Date
                    </TableCell>
                  )}
                  {props.subscriptionOp && (
                    <TableCell sx={{ width: "22.5%" }}>
                      MPESA Billing Start Date
                    </TableCell>
                  )}
                  <TableCell sx={{ width: "10%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{subscriptionServicesRows}</TableBody>
            </Table>
          </TableContainer>
        </ResponsiveRow>
        <ResponsiveRow
          item
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            width: "100%",
            height: "auto",
          }}
        >
          {!isNullUndefined(subscriptionType) &&
            !isEmptyString(subscriptionType) && (
              <ModalAcceptButton
                onClick={() => getTemplate()}
                sx={{ width: { xs: "100%", lg: "200px" } }}
              >
                Download Template
              </ModalAcceptButton>
            )}
          <ModalAcceptButton
            onClick={() => addSubscriptionServices()}
            sx={{ width: { xs: "100%", lg: "200px" } }}
          >
            Add Service
          </ModalAcceptButton>
        </ResponsiveRow>
      </Permit>
    </ModalContainerModule>
  );
}
