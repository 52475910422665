/* eslint-disable react-hooks/exhaustive-deps */
import {DetailsLabel, DetailsText, MainContainer} from "../constants/component.constants"
import UserDetailsCard from "../components/cards/userDetailsCard"
import {
    changeNotification,
    fetchSubscriber,
    generateAccounts,
    getLoggedInUser,
    getLoggedInUserEmailNotifications,
    getLoggedInUserNotifications,
    updateEmailNotification,
    updateUserData,
    uploadOrgLogo,
} from "../services/profile.service"
import {useEffect, useState} from "react"
import {isEmptyString, isNullUndefined, titleCase} from "../util/util"
import {useSelector} from "react-redux"
import {resetProfilePassword} from "../services/passwords.service"
import TransactionHistory from "../components/transactionHistory"
import {Alert, AlertTitle, Box, Typography} from "@mui/material"
import PropertyInfoCard from "../components/cards/propertyInfoCard"
import {getFacilityDetails} from "../services/list.service"
import Permit from "../util/permit"
import AddNewOrganisation from "../components/modals/addNewOrganisation"
import {issueResponseMessage, issueWarnMessage} from "../actions/message"
import {fetchOrganization} from "../services/organisation.service"
import {ResponsiveRow} from "../constants/layout.constants";
import Grid from "@mui/material/Grid";

export default function Profile() {
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedOrganization, setSelectedOrganization] = useState(null)
    const [selectedSubscriber, setSelectedSubscriber] = useState(null)
    const [selectedSubscription, setSelectedSubscription] = useState(null)
    const [selectedProperties, setSelectedProperties] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.auth.user)
    const [emailNotification, setEmailNotification] = useState(null)
    const [showOrganizationModal, setShowOrganizationModal] = useState(false)
    const [task, setTask] = useState("")
    const [bankBranchName] = useState("Equity Bank - Westlands Branch")
    const [bankAccountNo] = useState("0550273603024")
    const [bankAccountName] = useState("Villasoft Limited")
    const [mpesaPaybill] = useState("896654")
    useEffect(() => {
        setLoading(true)
        getLoggedInUser()
            .then((user) => {
                setSelectedUser(user)
            })
        getLoggedInUserNotifications()
            .then((notification) => {
                setNotification(notification)
            })
            .finally(() => {
                setLoading(false)
            })
        if (!isEmptyString(user.proxyFacility)) {
            getLoggedInUserEmailNotifications()
                .then((notification) => {
                    setEmailNotification(notification)
                })
        }
    }, [])

    useEffect(() => {
        if (!isEmptyString(user.proxyFacility)) {
            setLoading(true)
            getLoggedInUserEmailNotifications()
                .then((notification) => {
                    setEmailNotification(notification)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [user.proxyFacility])

    useEffect(() => {
        if (!isEmptyString(user.proxySubscription)) {
            setLoading(true)
            getFacilityDetails(user.proxySubscription)
                .then((response) => {
                    setSelectedProperties(response.properties)
                    setSelectedSubscriber(response.subscriber)
                    setSelectedSubscription(response.subscription)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [user.proxySubscription])

    function getUser() {
        setLoading(true)
        getLoggedInUser()
            .then((user) => {
                setSelectedUser(user)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function getEmailNotification() {
        if (!isNullUndefined(user.proxyFacility)) {
            setLoading(true)
            getLoggedInUserEmailNotifications()
                .then((notification) => {
                    setEmailNotification(notification)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    function getNotification() {
        setLoading(true)
        getLoggedInUserNotifications()
            .then((notification) => {
                setNotification(notification)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function handleUpdatePersonDetailsClick(selectedUser) {
        setSelectedUser(selectedUser)
        let user = selectedUser
        let userName = user.username.replace(/"/g, "")
        delete user.username
        user.credentialId = userName
        updateUserData(user)
            .then((response) => {
                issueResponseMessage(response)
                getUser()
            })
    }

    function handleGenerateAccountClick() {
        let params = {}
        params.subscriptionId = selectedSubscription.id
        generateAccounts(params)
            .then((response) => {
                issueResponseMessage(response)
                let personOrgId = ""
                if (!isNullUndefined(selectedSubscriber) && selectedSubscriber.type === "Company") {
                    personOrgId = selectedSubscriber.id + "_O"
                } else {
                    personOrgId = selectedSubscriber.id + "_P"
                }
                fetchSubscriber(personOrgId)
                    .then((response) => {
                        setSelectedSubscriber(response.subscriber)
                        setSelectedSubscription(response.subscription)
                    })
            })
    }

    function handleUploadLogoClick(file) {
        fetchOrganization(selectedSubscriber.id)
            .then((organization) => {
                if (isNullUndefined(organization.id)) {
                    issueWarnMessage("Couldn't determine the identifier for uploading the logo for this organization. Try refresh the page.")
                    return
                }
                let formData = new FormData()
                formData.append("orgLogoUpload", file)
                uploadOrgLogo(formData, organization.id)
                    .then((response) => {
                        issueResponseMessage(response)
                        let personOrgId = ""
                        if (!isNullUndefined(selectedSubscriber) && selectedSubscriber.type === "Company") {
                            personOrgId = selectedSubscriber.id + "_O"
                        } else {
                            personOrgId = selectedSubscriber.id + "_P"
                        }
                        fetchSubscriber(personOrgId)
                            .then((response) => {
                                setSelectedSubscriber(response.subscriber)
                                setSelectedSubscription(response.subscription)
                                setShowOrganizationModal(false)
                                setTask("")
                                setSelectedOrganization(null)
                            })
                    })
            })
    }

    function handleUpdatePasswordClick(paramsValue) {
        let user = selectedUser
        let params = paramsValue
        params.username = user.username.replace(/"/g, "")
        resetProfilePassword(params)
            .then((response) => {
                issueResponseMessage(response)
            })
    }

    function handleEditOrganizationClick() {
        fetchOrganization(selectedSubscriber.id)
            .then((organization) => {
                setSelectedOrganization(organization)
                setTask("Edit")
                setShowOrganizationModal(true)
            })
    }

    function handleOrgModalClose() {
        let personOrgId = ""
        if (!isNullUndefined(selectedSubscriber) && selectedSubscriber.type === "Company") {
            personOrgId = selectedSubscriber.id + "_O"
        } else {
            personOrgId = selectedSubscriber.id + "_P"
        }
        fetchSubscriber(personOrgId)
            .then((response) => {
                setSelectedSubscriber(response.subscriber)
                setShowOrganizationModal(false)
                setTask("")
                setSelectedOrganization(null)
            })
    }

    function handleUpdateNotificationsClick(notification) {
        changeNotification(notification)
            .then((response) => {
                issueResponseMessage(response)
                getNotification()
            })
    }

    function handleUpdateReportClick(emailNotification) {
        updateEmailNotification(emailNotification)
            .then((response) => {
                issueResponseMessage(response)
                getEmailNotification()
            })
    }

    return (
        <MainContainer>
            {/*ORGANISATION MODAL*/}
            {showOrganizationModal && (
                <AddNewOrganisation
                    openModal={showOrganizationModal}
                    closeModal={handleOrgModalClose}
                    task={task}
                    selectedOrg={selectedOrganization}
                />
            )}

            {
                !isNullUndefined(selectedSubscription) &&
                !isNullUndefined(selectedSubscription.statusCd) &&
                selectedSubscription.statusCd === "SUP" &&
                <Grid
                    container
                    spacing={1}
                    sx={{
                        padding: "20px",
                        width: { xs: "100%", md: "100%" },
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        margin: "20px auto",
                    }}
                >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        margin: "auto 20px",
                        width: "100%",
                        height: "fit-content",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "18px",
                        }}
                        variant="h4"
                    >
                        <Alert severity={"warning"}>
                            <AlertTitle>{titleCase("Your subscription is suspended.")}</AlertTitle>
                            {
                                "The subscription for " + selectedSubscription.subscriberName +
                                " is currently suspended as there is overdue balance of " + selectedSubscription.balance + ". " +
                                "To activate your subscription you can settle the balance using below information."
                            }
                        </Alert>
                    </Typography>

                    <Typography
                        sx={{
                            fontWeight: 800,
                            paddingTop: "20px",
                            fontSize: "18px",
                        }}
                        variant="h4"
                    >
                        Bank
                    </Typography>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        <ResponsiveRow
                            sx={{
                                display: "flex",
                                width: { xs: "100%", lg: "33.3%" },
                                justifyContent: "flex-start",
                            }}
                        >
                            <DetailsLabel
                                sx={{
                                    fontWeight: 700,
                                    textAlign: "start",
                                    maxWidth: "30%",
                                }}
                                variant="h6"
                            >
                                Account Name:
                            </DetailsLabel>
                            <DetailsText
                                sx={{
                                    color: "#037171",
                                    marginLeft: "5%",
                                    textAlign: "start",
                                    fontWeight: 600,
                                }}
                                variant="h6"
                            >
                                {bankAccountName}
                            </DetailsText>
                        </ResponsiveRow>

                        <ResponsiveRow
                            sx={{
                                width: { xs: "100%", lg: "33.3%" },
                            }}
                        >
                            <DetailsLabel
                                sx={{
                                    fontWeight: 700,
                                    textAlign: "start",
                                }}
                                variant="h6"
                            >
                                Bank Branch:
                            </DetailsLabel>
                            <DetailsText
                                sx={{
                                    color: "#037171",
                                    textAlign: "start",
                                    marginLeft: "5%",
                                    fontWeight: 600,
                                }}
                                variant="h6"
                            >
                                {bankBranchName}
                            </DetailsText>
                        </ResponsiveRow>

                        <ResponsiveRow
                            sx={{
                                width: { xs: "100%", lg: "33.3%" },
                            }}
                        >
                            <DetailsLabel
                                sx={{
                                    fontWeight: 700,
                                    textAlign: "start",
                                }}
                                variant="h6"
                            >
                                Account No:
                            </DetailsLabel>
                            <DetailsText
                                sx={{
                                    color: "#037171",
                                    textAlign: "start",
                                    marginLeft: "5%",
                                    fontWeight: 600,
                                }}
                                variant="h6"
                            >
                                {bankAccountNo}
                            </DetailsText>
                        </ResponsiveRow>
                    </ResponsiveRow>

                    <Typography
                        sx={{
                            fontWeight: 800,
                            paddingTop: "20px",
                            fontSize: "18px",
                        }}
                        variant="h4"
                    >
                       M-pesa
                    </Typography>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        <ResponsiveRow
                            sx={{
                                display: "flex",
                                width: { xs: "100%", lg: "33.3%" },
                                justifyContent: "flex-start",
                            }}
                        >
                            <DetailsLabel
                                sx={{
                                    fontWeight: 700,
                                    textAlign: "start",
                                    maxWidth: "30%",
                                }}
                                variant="h6"
                            >
                                Mpesa paybill:
                            </DetailsLabel>
                            <DetailsText
                                sx={{
                                    color: "#037171",
                                    marginLeft: "5%",
                                    textAlign: "start",
                                    fontWeight: 600,
                                }}
                                variant="h6"
                            >
                                {mpesaPaybill}
                            </DetailsText>
                        </ResponsiveRow>

                        <ResponsiveRow
                            sx={{
                                width: { xs: "100%", lg: "33.3%" },
                            }}
                        >
                            <DetailsLabel
                                sx={{
                                    fontWeight: 700,
                                    textAlign: "start",
                                }}
                                variant="h6"
                            >
                                Account No:
                            </DetailsLabel>
                            <DetailsText
                                sx={{
                                    color: "#037171",
                                    textAlign: "start",
                                    marginLeft: "5%",
                                    fontWeight: 600,
                                }}
                                variant="h6"
                            >
                                {selectedSubscription.billingCode}
                            </DetailsText>
                        </ResponsiveRow>

                        <ResponsiveRow
                            sx={{
                                width: { xs: "100%", lg: "33.3%" },
                            }}
                        >
                            <DetailsLabel
                                sx={{
                                    fontWeight: 700,
                                    textAlign: "start",
                                }}
                                variant="h6"
                            >
                                Amount:
                            </DetailsLabel>
                            <DetailsText
                                sx={{
                                    color: "#037171",
                                    textAlign: "start",
                                    marginLeft: "5%",
                                    fontWeight: 600,
                                }}
                                variant="h6"
                            >
                                {selectedSubscription.balance}
                            </DetailsText>
                        </ResponsiveRow>
                    </ResponsiveRow>
                </Box>
                </Grid>
            }

            {!isNullUndefined(selectedUser) && !isNullUndefined(notification) && (
                <UserDetailsCard
                    selectedUser={selectedUser}
                    notification={notification}
                    emailNotification={emailNotification}
                    loading={loading}
                    handleUpdatePersonDetailsClick={handleUpdatePersonDetailsClick}
                    handleUpdateNotificationsClick={handleUpdateNotificationsClick}
                    handleUpdatePasswordClick={handleUpdatePasswordClick}
                    handleUpdateReportClick={handleUpdateReportClick}
                />
            )}

            <Permit roles="BLDIR">
                {!isNullUndefined(selectedSubscriber) && (
                    <PropertyInfoCard
                        selectedSubscriber={selectedSubscriber}
                        selectedSubscription={selectedSubscription}
                        selectedProperties={selectedProperties}
                        handleGenerateAccountClick={handleGenerateAccountClick}
                        handleUploadLogoClick={handleUploadLogoClick}
                        handleEditOrganizationClick={handleEditOrganizationClick}
                    />
                )}

                {!isNullUndefined(selectedSubscription) && (
                    <Box
                        sx={{
                            width: "100%",
                            marginBottom: "40px",
                        }}
                    >
                        <TransactionHistory
                            transactionMode={"Client"}
                            contract={selectedSubscription}
                            hideVoid={true}
                            height="55vh"
                        />
                    </Box>
                )}
            </Permit>
        </MainContainer>
    )
}
