/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  Container,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import history from "../../helpers/history";
import { clearMessage } from "../../actions/message";
import { executeProxy, logout } from "../../actions/auth";
import Permit from "../../util/permit";
import { SupportAgent } from "@mui/icons-material";
import DesktopNavigation from "../modules/desktopNavigation";
import MobileNavigation from "../modules/mobileNavigation";
import { ResponsiveRow } from "../../constants/layout.constants";
import { isArray, isEmptyString, isNullUndefined } from "../../util/util";
import { fetchOptionsGet } from "../../services/list.service";
import {
  PROXY_FAC_FOR_USER_OPT_URL,
  PROXY_FAC_URL,
} from "../../constants/url.constants";
import { PROXY_FAC } from "../../actions/type";
import { getFacilityName } from "../../services/property.service";
import kodi_theme from "../../themes/kodiThemeProvider";
import { loadDaysOverdue } from "../../services/subscription.service";

const navigationData = [
  {
    id: "DashboardButton",
    services: null,
    permissions: null,
    roles: null,
    name: "Dashboard",
    href: "/dashboard",
    children: null,
  },
  {
    id: "responsiveUsersButton",
    roles: "BLDIR,BLDMG,CS",
    permissions: null,
    services: "PREMIUM,CORE,HOA,BLWTR,METER,MAINT",
    name: "Manage Users",
    href: "/users",
    children: null,
  },
  {
    id: "responsiveUsersButton",
    roles: "SYSAD,BLDIR,BLDMG,CS",
    permissions: null,
    services: "BLWTR,MAINT",
    name: "Customers",
    href: "/customers",
    children: null,
  },
  {
    id: "subscriptions",
    roles: "SYSAD,CS",
    services: null,
    permissions: null,
    name: "Subscriptions",
    href: "/subscriptions",
    children: null,
  },
  {
    id: "signUpAnalysis",
    roles: "SYSAD,CS",
    services: null,
    permissions: null,
    name: "SignUp Analysis",
    href: "/signUpAnalysis",
    children: null,
  },
  {
    id: "subscriptionServices",
    roles: "SYSAD",
    services: null,
    permissions: null,
    name: "Subscription Services",
    href: "/subscriptionServices",
    children: null,
  },
  {
    id: "responsiveMobileMoneyButton",
    roles: "SYSAD,BLDIR,CS",
    services: "PREMIUM,HOA,CORE,LEAN,METER",
    permissions: null,
    name: "Mobile Money",
    children: [
      {
        id: "responsiveMobileMoneySummary",
        name: "Summary",
        href: "/mobileMoneySummary",
        roles: "SYSAD",
        services: null,
        permissions: null,
      },
      {
        id: "responsiveMobileMoneyReconciliation",
        name: "Reconciliation",
        href: "/mobileMoneyReconciliation",
        roles: "BLDIR,CS",
        services: "PREMIUM,HOA,LEAN,METER,BLWTR,CORE",
        permissions: null,
      },
      {
        id: "responsiveMobileMoneyTransferFunds",
        name: "Transfer Funds",
        href: "/mobileMoneyTransfer",
        roles: "BLDIR,SYSAD",
        services: "PREMIUM,HOA,LEAN,METER,BLWTR,CORE",
        permissions: null,
      },
    ],
  },

  {
    id: "responsiveMessagesButton",
    roles: "BLDIR,BLDMG,CS",
    services: "PREMIUM,HOA,LEAN,METER,CORE",
    permissions: null,
    name: "Messages",
    href: "/messages",
    children: null,
  },
  {
    id: "responsiveUtilitiesButton",
    roles: "BLDIR,BLDMG,REC,CS,ACC",
    services: "METER,BLWTR",
    name: "Utilities",
    children: [
      {
        id: "responsiveUtilitiesProperties",
        name: "Properties",
        href: "/property",
        roles: "BLDIR,BLDMG,CS",
        services: "METER,BLWTR",
        permissions: null,
      },
      {
        id: "responsiveUtilitiesBilling",
        name: "Billing",
        href: "/meterBilling",
        roles: "BLDIR,BLDMG,REC,CS",
        services: "METER",
        permissions: null,
      },
      {
        id: "responsiveUtilitiesTerminatedBilling",
        name: "Terminated Billing",
        href: "/terminatedMeterBilling",
        roles: "BLDIR,BLDMG,CS",
        services: "METER",
        permissions: null,
      },
      {
        id: "responsiveUtilitiesAccountHolders",
        name: "Account Holders",
        href: "/tenants",
        roles: "BLDIR,BLDMG,CS",
        services: "METER",
        permissions: null,
      },
      {
        id: "responsiveUtilitiesdMeters",
        name: "Meters",
        href: "/meters",
        roles: "BLDIR,BLDMG,REC,CS,ACC",
        services: "METER,BLWTR",
        permissions: null,
      },
      {
        id: "responsiveUtilitiesArchivedMeters",
        name: "Archived Meters",
        href: "/archivedMeters",
        roles: "BLDIR,BLDMG,CS",
        services: "METER,BLWTR",
        permissions: null,
      },
    ],
  },
  {
    id: "responsiveRentalsButton",
    roles: "BLDIR,BLDMG,REC,OWN,CS",
    services: "PREMIUM,LEAN,CORE",
    permissions: null,
    name: "Rentals",
    children: [
      {
        id: "responsiveRentalsComplex",
        name: "Complexes",
        href: "/complex",
        roles: "BLDIR,BLDMG,CS",
        services: "PREMIUM",
        permissions: null,
      },
      {
        id: "responsiveRentalProperties",
        name: "Properties",
        href: "/property",
        roles: "BLDIR,BLDMG,CS",
        services: "PREMIUM,LEAN,CORE",
        permissions: null,
      },
      {
        id: "responsiveRentalLeases",
        name: "Leases",
        href: "/leases",
        roles: "BLDIR,BLDMG,REC,OWN,CS",
        services: "PREMIUM,LEAN,CORE",
        permissions: null,
      },
      {
        id: "responsiveRentalTerminatedLeases",
        name: "Terminated Leases",
        href: "/terminatedLeases",
        roles: "BLDIR,BLDMG,OWN,CS",
        services: "PREMIUM,LEAN,CORE",
        permissions: null,
      },
      {
        id: "responsiveRentalsTenants",
        name: "Tenants",
        href: "/tenants",
        roles: "BLDIR,BLDMG,OWN,REC,CS",
        services: "PREMIUM,LEAN,CORE",
        permissions: null,
      },
      {
        id: "responsiveRentalMeters",
        name: "Meters",
        href: "/meters",
        roles: "BLDIR,BLDMG,OWN,REC,CS",
        services: "PREMIUM,METER",
        permissions: null,
      },
      {
        id: "responsiveRentalArchivedMeters",
        name: "Archived Meters",
        href: "/archivedMeters",
        roles: "BLDIR,BLDMG,OWN,CS",
        services: "PREMIUM,METER",
        permissions: null,
      },
    ],
  },

  {
    id: "MyHomeButton",
    services: "HOA",
    permissions: null,
    roles: "OWN",
    name: "My Home",
    href: "#",
    children: null,
  },

  {
    id: "MyTenantsButton",
    services: "HOA",
    permissions: null,
    roles: "OWN",
    name: "My Tenants",
    href: "#",
    children: null,
  },

  {
    id: "responsivePropertyButton",
    roles: "BLDIR,BLDMG,REC,ACC,CS",
    services: "MAINT",
    permissions: null,
    name: "Property",
    children: [
      {
        id: "responsiveRentalProperties",
        name: "Facility",
        href: "/property",
        roles: "BLDIR,BLDMG,CS",
        services: "MAINT",
        permissions: null,
      },
      {
        id: "responsiveRentalMeters",
        name: "Meters",
        href: "/meters",
        roles: "BLDIR,BLDMG,OWN,REC,CS",
        services: "MAINT",
        permissions: null,
      },
    ],
  },

  {
    id: "responsiveHomesButton",
    roles: "BLDIR,BLDMG,REC,ACC,CS",
    services: "HOA",
    permissions: null,
    name: "Homes",
    children: [
      {
        id: "responsiveHomeComplex",
        name: "Complexes",
        href: "/complex",
        roles: "BLDIR,BLDMG,CS",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveHomeProperty",
        name: "Properties",
        href: "/property",
        roles: "BLDIR,BLDMG,CS",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveHomeOwnerships",
        name: "Ownerships",
        href: "/ownerships",
        roles: " BLDIR,BLDMG,REC,CS",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveHomesTerminatedOwnerships",
        name: "Terminated Ownerships",
        href: "/terminatedOwnerships",
        roles: "BLDIR,BLDMG,CS",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveHomeOwners",
        name: "Capital Funds",
        href: "/capitalFund",
        roles: "BLDIR,BLDMG,CS",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveHomeBudgeting",
        name: "Budgeting",
        href: "/budget",
        roles: "BLDIR,ACC",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveHomeOwners",
        name: "Home Owners",
        href: "/owners",
        roles: "BLDIR,BLDMG,ACC,CS",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveHomeMeters",
        name: "Meters",
        href: "/meters",
        roles: "BLDIR,BLDMG,REC,CS",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveHomeArchivedMeters",
        name: "Archived Meters",
        href: "/archivedMeters",
        roles: "BLDIR,BLDMG,CS",
        services: "HOA",
        permissions: null,
      },
      {
        id: "responsiveTenants",
        name: "Tenants",
        href: "/tenants",
        roles: "BLDIR,BLDMG,CS",
        services: "HOA",
        permissions: null,
      },
    ],
  },
  {
    id: "responsiveMaintenanceButton",
    roles: "BLDIR,BLDMG,ACC,CS",
    services: "PREMIUM,HOA",
    permissions: null,
    name: "Maintenance",
    children: [
      {
        id: "responsiveMaintenanceAssets",
        name: "Assets",
        href: "/assets",
        roles: "BLDIR",
        services: "PREMIUM,HOA",
        permissions: null,
      },
      {
        id: "responsiveMaintenanceVendors",
        name: "Vendors",
        href: "/vendors",
        roles: "BLDIR",
        services: "PREMIUM,HOA",
        permissions: null,
      },
      {
        id: "responsiveMaintenanceQuotation",
        name: "Quotation",
        href: "/quotations",
        roles: "BLDIR,BLDMG,CS",
        services: "PREMIUM",
        permissions: null,
      },
      {
        id: "responsiveMaintenanceWorkOrders",
        name: "Work Orders",
        href: "/workOrders",
        roles: "BLDIR,BLDMG,ACC,CS",
        services: "PREMIUM,HOA",
        permissions: null,
      },
    ],
  },
  // {
  //   id: "responsiveMaintenanceButton",
  //   roles: "BLDIR,BLDMG,ACC,CS",
  //   services: "MAINT",
  //   permissions: null,
  //   name: "Maintenance",
  //   children: [
  //     {
  //       id: "responsiveMaintenanceAssets",
  //       name: "Equipment",
  //       href: "/assets",
  //       roles: "BLDIR",
  //       services: "MAINT",
  //       permissions: null,
  //     },
  //     {
  //       id: "responsiveMaintenanceVendors",
  //       name: "Vendors",
  //       href: "/vendors",
  //       roles: "BLDIR",
  //       services: "MAINT",
  //       permissions: null,
  //     },
  //     {
  //       id: "responsiveMaintenanceQuotation",
  //       name: "Quotation",
  //       href: "/quotations",
  //       roles: "BLDIR,BLDMG,CS",
  //       services: "MAINT",
  //       permissions: null,
  //     },
  //     {
  //       id: "responsiveMaintenanceWorkOrders",
  //       name: "Work Orders",
  //       href: "/workOrders",
  //       roles: "BLDIR,BLDMG,ACC,CS",
  //       services: "MAINT",
  //       permissions: null,
  //     },
  //   ],
  // },
  {
    id: "responsiveAccountingButton",
    roles: "BLDIR,ACC,BLDMG,CS,REC",
    services: "PREMIUM,CORE,LEAN,HOA,METER,MAINT",
    permissions: null,
    name: "Accounting",
    children: [
      {
        id: "responsiveAccountingBankAccounts",
        name: "Bank Accounts",
        href: "/bank",
        roles: "BLDIR",
        services: "PREMIUM,HOA,METER,MAINT,METER",
        permissions: null,
      },
      // {
      // 	id: 'responsiveAccounts',
      // 	name: 'Chart of Accounts',
      // 	href: '/accounts',
      // 	roles: 'BLDIR,ACC,BLDMG,CS',
      // 	services: 'PREMIUM,HOA,MAINT,METER',
      // 	permissions: null,
      // },
      {
        id: "responsiveAccountingBills",
        name: "Bills & Payments",
        href: "/billsPayments",
        roles: "BLDIR,BLDMG,ACC,CS",
        services: "PREMIUM,HOA,MAINT",
        permissions: null,
      },
      //   {
      //     id: "responsiveAccountingInvoice",
      //     name: "Invoice",
      //     href: "/invoices",
      //     roles: "BLDIR,BLDMG,ACC,CS,REC",
      //     services: "PREMIUM,HOA,LEAN,CORE,MAINT,METER",
      //     permissions: null,
      //   },
      {
        id: "responsiveAccountingJournalEntries",
        name: "J.E. & Chart of Accounts",
        href: "/journalEntries",
        roles: "BLDIR,ACC",
        services: "PREMIUM,HOA,MAINT",
        permissions: null,
      },
      // {
      // 	id: 'responsiveAccountingJournalEntries',
      // 	name: 'Journal Entries',
      // 	href: '/journalEntries',
      // 	roles: 'BLDIR,ACC',
      // 	services: 'PREMIUM,HOA,MAINT',
      // 	permissions: null,
      // },
      {
        id: "responsiveAccountingPettyCash",
        name: "Petty Cash",
        href: "/pettyCash",
        roles: "BLDIR,ACC",
        services: "PREMIUM,HOA,MAINT",
        permissions: null,
      },
      //   {
      //     id: "responsiveAccountingReceipts",
      //     name: "Receipt",
      //     href: "/receipts",
      //     roles: "BLDIR,BLDMG,ACC,CS,REC",
      //     services: "PREMIUM,HOA,LEAN,CORE,MAINT,METER",
      //     permissions: null,
      //   },
//      {
//        id: "responsiveAccountingReconciliation",
//        name: "Reconciliation",
//        href: "/reconciliation",
//        roles: "BLDIR,ACC,BLDMG,CS",
//        services: "PREMIUM,HOA,MAINT",
//        permissions: null,
//      },
        //Hide for now
     {
        id: "responsiveAccountingTaxes",
        name: "Taxes",
        href: "/taxes",
        roles: "BLDIR,ACC",
        services: "PREMIUM,HOA,MAINT",
        permissions: null,
      },
      {
        id: "responsiveAccountingTransactions",
        name: "Transactions",
        href: "/transactions",
        roles: "BLDIR,BLDMG,ACC,CS,REC",
        services: "PREMIUM,HOA,LEAN,CORE,MAINT,METER",
        permissions: null,
      },
      //   {
      //     id: "responsiveAccountingCreditNotes",
      //     name: "CreditNotes",
      //     href: "/creditNotes",
      //     roles: "BLDIR,BLDMG,ACC,CS",
      //     services: "PREMIUM,HOA,LEAN,CORE,MAINT,METER",
      //     permissions: null,
      //   },
      //   {
      //     id: "responsiveAccountingDebitNotes",
      //     name: "Debit Notes",
      //     href: "/debitNotes",
      //     roles: "BLDIR,BLDMG,ACC,CS",
      //     services: "PREMIUM,HOA,LEAN,CORE,MAINT,METER",
      //     permissions: null,
      //   },
    ],
  },
];

const reportsData = {
  id: "responsiveReportsButton",
  roles: "BLDIR,BLDMG,ACC,CS,REC",
  services: null,
  permissions: null,
  name: "Reports",
  children: [
    {
      name: "Balance Sheet",
      description: "A financial statement that reports a property's assets, liabilities,",
      roles: "BLDIR,ACC,CS",
      services: "PREMIUM,METER,CORE,HOA",
      permissions: null,
      selector: "Balance Sheet",
      category: "Financial Reports",
    },
    {
      name: "Budget Vs Actual Report",
      description: "",
      roles: "BLDIR,ACC,CS",
      services: "HOA",
      permissions: null,
      selector: "Budget Vs Actual",
      category: "Financial Statements",
    },
    {
      name: "Collections Report",
      description:
        "The Collection Summary report provides a summary of receipts collected, how they were applied (invoices, debit memos, service charges, or un-applied), and average number of days to collection, to help you determine a client's payment habits.",
      roles: "BLDIR,BLDMG,ACC,CS,REC",
      services: "PREMIUM,METER,CORE,MAINT",
      permissions: null,
      selector: "Collections",
      category: "Property and Facility Reports",
    },
    {
      name: "Collections Report",
      description:
        "The Collection Summary report provides a summary of receipts collected, how they were applied (invoices, debit memos, service charges, or un-applied), and average number of days to collection, to help you determine a client's payment habits.",
      roles: "BLDIR,BLDMG,ACC,CS,REC",
      services: "HOA",
      permissions: null,
      selector: "Collections",
      category: "Financial Analysis and Tracking",
    },
    {
      name: "Collections by Capital Fund Report",
      description: "",
      roles: "BLDIR,ACC,REC,CS",
      services: "HOA",
      permissions: null,
      selector: "Collections By Capital Fund",
      category: "Financial Analysis and Tracking",
    },
    {
      name: "Collections by Service Charge Report",
      description: "",
      roles: "BLDIR,ACC,REC,CS",
      services: "HOA",
      permissions: null,
      selector: "Collections By Service Charge",
      category: "Financial Analysis and Tracking",
    },
    {
      name: "Collections by Utilities Report",
      description: "",
      roles: "BLDIR,ACC,REC,CS",
      services: "HOA",
      permissions: null,
      selector: "Collections By Utilities",
      category: "Financial Analysis and Tracking",
    },
    {
      name: "Customer Balances Report",
      description: "",
      roles: "BLDIR,CS,REC",
      services: "BLWTR",
      permissions: null,
      selector: "Customer Balances",
      category: "Property and Facility Reports",
    },
    {
      name: "Daily Sales Report",
      description: "",
      roles: "BLDIR,CS",
      services: "BLWTR",
      permissions: null,
      selector: "Daily Sales",
      category: "Financial Reports",
    },

    // {
    //     name: "Facility Report",
    //     description: "",
    //     roles: "BLDIR",
    //     services: "PREMIUM,METER",
    //     permissions: null,
    //     selector: "Facility",
    // },
    {
      name: "Income Statement Report",
      description:
        "A report that shows income and expenses by month, along with a running year-to-date total and year-end summary.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "HOA",
      permissions: null,
      selector: "Income",
      category: "Financial Reports",
    },
    {
      name: "Income Statement Report",
      description:
        "A report that shows income and expenses by month, along with a running year-to-date total and year-end summary.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,METER,CORE",
      permissions: null,
      selector: "Income",
      category: "Financial Analysis and Tracking",
    },
    {
      name: "Petty Cash Book Report",
      description:
        "A report that capture and include all transactions made using petty cash account.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,HOA,LEAN,CORE,METER,MAINT",
      permissions: null,
      selector: "Petty Cash",
      category: "Financial Reports",
    },
    {
      name: "Bank Account Cash Book Report",
      description:
        "A report that capture and include all transactions that affect the bank account in the Bank Account Cash Book Report.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,METER,MAINT,HOA",
      permissions: null,
      selector: "Bank Account Cash Book",
      category: "Financial Reports",
    },
    {
      name: "Work Order Report",
      description:
        "This report provides an overview of all work orders generated within a specified period.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,HOA,MAINT",
      permissions: null,
      selector: "Work Order",
      category: "Maintenance and Work Orders",
    },
    {
      name: "Work Order Report",
      description:
        "This report provides an overview of all work orders generated within a specified period.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "HOA",
      permissions: null,
      selector: "Work Order",
      category: "Maintenance and Work Order Reports",
    },
    {
      name: "Equipment Maintenance Report",
      description:
        "This report tracks the maintenance history and schedules for equipment and assets within the property.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "MAINT",
      permissions: null,
      selector: "Equipment Maintenance",
    },
    {
      name: "Maintenance Request Report",
      description:
        "This report summarizes all maintenance requests submitted by tenants or property owners.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "MAINT",
      permissions: null,
      selector: "Maintenance Request",
      category: "Maintenance and Work Order Reports",
    },
    {
      name: "Management Income Report",
      description:
        "Property management periodic review of the financial condition of a property organization.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,METER,CORE",
      permissions: null,
      selector: "Management",
      category: "Property and Facility Reports",
    },

    {
      name: "Meter Variance Report",
      description: "",
      roles: "BLDIR,CS,ACC",
      services: "BLWTR",
      permissions: null,
      selector: "Meter Variance",
      category: "Property and Facility Reports",
    },
    {
      name: "Mobile Money Report",
      description:
        "A statement indicating how much Villasoft is remitting to each client.",
      roles: "BLDIR,ACC,CS",
      services: "PREMIUM,METER,CORE",
      permissions: null,
      selector: "Mobile Money",
      category: "Financial Reports",
    },
    {
      name: "Mobile Money Report",
      description:
        "A statement indicating how much Villasoft is remitting to each client.",
      roles: "BLDIR,ACC,CS",
      services: "HOA",
      permissions: null,
      selector: "Mobile Money",
      category: "Financial Analysis and Tracking",
    },
    {
      name: "Portfolio Rent/Payment Roll Report",
      description: "",
      roles: "BLDIR,BLDMG,OWN,CS",
      services: "PREMIUM",
      permissions: null,
      selector: "Portfolio",
      category: "Property and Facility Reports",
    },
    // {
    //   name: "Meter Sales Outstanding Balances Report",
    //   roles: "BLDIR",
    //   services: null,
    //   permissions: null,
    //   selector: "Outstanding Balances",
    // },
    {
      name: "Reconciliation Report",
      description:
        "A summary of lease activity that reconciles a property's Mpesa account with its financial records.",
      roles: "BLDIR,ACC,CS",
      services: "PREMIUM",
      permissions: null,
      selector: "Reconciliation",
      category: "Property and Facility Reports",
    },
    {
      name: "Reconciliation Report",
      description:
        "A summary of lease activity that reconciles a property's Mpesa account with its financial records.",
      roles: "BLDIR,ACC,CS",
      services: "HOA",
      permissions: null,
      selector: "Reconciliation",
      category: "Financial Analysis and Tracking",
    },
    {
      name: "Rent Roll Report",
      description:
        "A report that provides detailed information about the property, the tenant, and rents",
      roles: "BLDIR,BLDMG,OWN,ACC,CS",
      services: "PREMIUM,CORE",
      permissions: null,
      selector: "Rent Roll",
      category: "Financial Reports",
    },
    // {
    //   name: "SmartKodi Mobile Money Report",
    //   roles: null,
    //   services: null,
    //   permissions: null,
    //   selector: "Smartkodi Mobile Money",
    // },
    {
      name: "Electricity: Income vs Expenses Report",
      description: "",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,METER",
      permissions: null,
      selector: "Electricity",
      category: "Property and Facility Reports",
    },
    {
      name: "Water: Income vs Expenses Report",
      description: "",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,LEAN,CORE,METER",
      permissions: null,
      selector: "Water",
      category: "Financial Reports",
    },
    {
      name: "Water: Income vs Expenses Report",
      description: "",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "HOA",
      permissions: null,
      selector: "Water",
      category: "Financial Statements",
    },
    {
      name: "Vacant Units Report",
      description:
        "The Vacant units report shows a list of all vacant units as of today, by property.",
      roles: "BLDIR,CS",
      services: "PREMIUM",
      permissions: null,
      selector: "Vacant Units",
      category: "Vendor and Expense Reports",
    },
    {
      name: "VAT Report",
      description:
        "A financial document that summarizes a property's VAT-eligible transactions over a specified accounting period",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,HOA,LEAN,CORE,METER,MAINT",
      permissions: null,
      selector: "VAT",
      category: "Financial Reports",
    },
    {
      name: "Withholding Tax Report",
      description:
        "A financial document that summarizes a property's Withholding tax transactions over a specified accounting period",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,HOA,LEAN,CORE,METER,MAINT",
      permissions: null,
      selector: "Withholding Tax",
      category: "Financial Reports",
    },
    // {
    //     name: "Upcoming Maintenance Report",
    //     description:
    //         "A report that hold specific data about inspections and tasks as well as how they affect your maintenance operation.",
    //     roles: "BLDIR,BLDMG,CS",
    //     services: "PREMIUM,HOA",
    //     permissions: null,
    //     selector: "Upcoming Maintenance",
    // },
    {
      name: "Vendor Report",
      description:
        "A report that gives a detailed look at vendor details, linked work orders and Bills/Payments.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,HOA,MAINT",
      permissions: null,
      selector: "Vendor Report",
      category: "Vendor and Expense Reports",
    },
    {
      name: "Historical Maintenance Report",
      description:
        "This report helps in identifying recurring issues, analyzing trends, and making data-driven maintenance decisions.",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,HOA,MAINT",
      permissions: null,
      selector: "Maintenance History Report",
      category: "Maintenance and Work Orders",
    },
    {
      name: "Maintenance Cost Analysis Report",
      description:
        "This report provides a detailed breakdown of maintenance expenses incurred during a specific period. ",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,HOA,MAINT",
      permissions: null,
      selector: "Maintenance Cost Analysis Report",
      category: "Maintenance and Work Orders",
    },
    {
      name: "General Ledger Report",
      description: "General Ledger Report ",
      roles: "BLDIR,BLDMG,ACC,CS",
      services: "PREMIUM,HOA,MAINT",
      permissions: null,
      selector: "General Ledger Report",
      category: "Maintenance and Work Orders",
    },
  ],
};

export default function NavigationBar(props) {
  const [anchorElFacility, setAnchorElFacility] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState("");
  const [currentFacilityName, setCurrentFacilityName] = useState("");
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [facilityList, setFacilityList] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState("");
  const [showSearchResults, setShowSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [daysOverdue, setDaysOverdue] = useState(-1);

  useEffect(() => {
    getDaysOverdue(user && user.proxySubscription);

    return () => {
      setSearchResults([]);
      setSearchQuery("");
    };
  }, [user && user.proxySubscription]);

  const getDaysOverdue = (subscriptionId) => {
    const sendData = {
      subscriptionId: subscriptionId,
    };
    loadDaysOverdue(sendData).then((response) => {
      setDaysOverdue(response && response.daysOverdue);
    });
  };

  useEffect(() => {
    const daysToStartCountDown = 30; //start count down if days are greater than this
    const daysToSuspendAccount = 45; //suspend account after these days on controller side

    if (daysOverdue >= daysToStartCountDown) {
      if (daysOverdue > daysToSuspendAccount) {
        setSubscriptionStatus("Suspended");
      } else if (daysOverdue === daysToSuspendAccount) {
        setSubscriptionStatus("Will be suspended tonight");
      } else {
        let suspendedIn = daysToSuspendAccount - daysOverdue;
        if (suspendedIn === 1) {
          setSubscriptionStatus("Will be suspended tomorrow");
        } else {
          setSubscriptionStatus(" " + suspendedIn + " days to Suspension");
        }
      }
    } else {
      setSubscriptionStatus("");
    }
  }, [daysOverdue]);

  function handleSearchFilter(ev) {
    const newQuery = String(ev.target.value).toLowerCase();
    setSearchQuery(newQuery);
    const searchQueryMatches = [];
    facilityList.forEach((facility) => {
      const facilityName = String(facility.label).toLowerCase();
      if (String(facilityName).includes(searchQuery)) {
        searchQueryMatches.push(facility);
      }
    });

    if (searchQueryMatches.length > 0) {
      setShowSearchResults(true);
      setSearchResults(searchQueryMatches);
    }
  }

  useEffect(() => {
    if (!isNullUndefined(user)) {
      if (
        !isNullUndefined(user.proxyFacility) &&
        !isEmptyString(user.proxyFacility) &&
        user.proxyFacility !== "null"
      ) {
        setSelectedFacility(user.proxyFacility);
        getFacilityName(user.proxyFacility).then((facilityName) =>
          setCurrentFacilityName(facilityName)
        );
      }
      if (user.proxyRole === "CS") {
        fetchOptionsGet(PROXY_FAC_FOR_USER_OPT_URL).then((data) => {
          setFacilityList(data);
        });
      } else if (
        !isNullUndefined(user.proxySubscription) &&
        !isEmptyString(user.proxySubscription) &&
        !isNullUndefined(user.proxyRole) &&
        !isEmptyString(user.proxyRole)
      ) {
        fetchOptionsGet(
          PROXY_FAC_URL + "?subscriptionId=" + user.proxySubscription
        ).then((data) => {
          setFacilityList(data);
        });
      } else {
        fetchOptionsGet(PROXY_FAC_FOR_USER_OPT_URL).then((data) => {
          setFacilityList(data);
        });
      }
      if (isArray(user.services)) {
        if (user.services.includes("PREMIUM")) {
          setCurrentSubscription("Premium");
        } else if (user.services.includes("CORE")) {
          setCurrentSubscription("Core");
        } else if (user.services.includes("LEAN")) {
          setCurrentSubscription("Lean");
        } else if (user.services.includes("HOA")) {
          setCurrentSubscription("HOA");
        } else if (user.services.includes("METER")) {
          setCurrentSubscription("Utilities");
        } else if (user.services.includes("BLWTR")) {
          setCurrentSubscription("Bulk Water");
        } else if (user.services.includes("MAINT")) {
          setCurrentSubscription("Maintenance");
        } else {
          setCurrentSubscription("None");
        }
      }
    } else {
      logout();
    }
  }, [user]);

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const openUserProfile = () => {
    setAnchorElUser(null);
    profile();
  };

  const logoutUser = () => {
    setAnchorElUser(null);
    logOut();
  };

  const openFacility = Boolean(anchorElFacility);

  const attachFacilityAnchorEL = (e) => {
    setAnchorElFacility(e.currentTarget);
  };

  const detachFacilityAnchor = (selectedFacilityValue) => {
    if (
      !isNullUndefined(selectedFacilityValue) &&
      selectedFacilityValue !== selectedFacility
    ) {
      dispatch({
        type: PROXY_FAC,
        payload: selectedFacilityValue,
      });
      dispatch(executeProxy("", "", selectedFacilityValue));
      setSelectedFacility(selectedFacility);
      setAnchorElFacility(null);
    }
  };

  const openUser = Boolean(anchorElUser);

  const attachUserAnchorEL = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const detachUserAnchor = () => {
    setAnchorElUser(null);
  };

  const logOut = () => {
    dispatch(logout());
    navigate("/");
  };

  const profile = () => {
    navigate("/profile");
  };

  return (
    <>
      {user && (
        <AppBar position="static" elevation={5}>
          <Container maxWidth="xl">
            <Toolbar
              component="div"
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "flex-start",
                padding: 0,
                margin: "auto, 0",
              }}
              disableGutters
            >
              {/*Mobile NAV*/}
              {
                  user && user.subscriptionStatusCd !== 'SUP' &&
                  <Grid sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="responsive-menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setMobileDrawer(!mobileDrawer)}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <MobileNavigation
                  menus={navigationData}
                  closeDrawer={() => {
                    setMobileDrawer(false);
                  }}
                  drawer={mobileDrawer}
                  openDrawer={() => {
                    setMobileDrawer(true);
                  }}
                  reports={reportsData}
                />
              </Grid>
            }

              {/*WEB NAV*/}
              {
                user && user.subscriptionStatusCd !== 'SUP' &&
                <Grid sx={{ display: { xs: "none", lg: "flex" } }}>
                <Box
                  component="img"
                  src={require("../../images/kodi_icon.png")}
                  sx={{
                    objectFit: "contain",
                    objectPosition: "center center",
                    width: "50px",
                    height: "50px",
                    margin: "auto ",
                  }}
                  alt=""
                />
                <DesktopNavigation
                  menus={navigationData}
                  reports={reportsData}
                />
              </Grid>
            }

              {/*GLOBAL NAV*/}
              <ResponsiveRow
                sx={{
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "auto",
                }}
              >
                <Permit roles="BLDIR,BLDMG,ACC,OWN,REC,CS">
                  <Tooltip title="Support">
                    <IconButton
                      id="faqIconButton"
                      sx={{
                        backgroundColor: "#037171",
                        margin: "7px 0 0 0",
                        height: "auto",
                      }}
                      aria-label=""
                      aria-haspopup="true"
                    >
                      <NavLink to="/faq" style={{ height: "auto" }}>
                        <SupportAgent
                          sx={{
                            backgroundColor: "#037171",
                            color: "#fff",
                          }}
                        />
                      </NavLink>
                    </IconButton>
                  </Tooltip>
                </Permit>

                <Permit roles="BLDIR,BLDMG,ACC,OWN,REC,CS">
                  <IconButton
                    aria-label="Facilities"
                    sx={{
                      display: "flex",
                      flexDirection: "column",

                      position: "relative",
                    }}
                    aria-controls={
                      openFacility ? "facilitySelectorMenu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFacility ? "true" : undefined}
                    onClick={attachFacilityAnchorEL}
                  >
                    <Tooltip title="Permitted Facilities">
                      <Avatar
                        sx={{
                          backgroundColor: "#037171",
                        }}
                      >
                        <CorporateFareIcon
                          sx={{ backgroundColor: "#037171" }}
                        />
                      </Avatar>
                    </Tooltip>
                    {!isEmptyString(currentFacilityName) && (
                      <Tooltip
                        title={`${currentFacilityName}: ${currentSubscription}`}
                      >
                        <ResponsiveRow
                          sx={{
                            position: "absolute",
                            zIndex: 6,
                            top: "50px",
                            right: "0",
                            textAlign: "start",
                            fontWeight: 800,
                            fontSize: ".8rem",
                            borderRadius: "4px",
                            height: "25px",
                            minWidth: "200px",
                            maxWidth: "40vw",
                            padding: 0,
                            flexWrap: "nowrap",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Chip
                            sx={{
                              color: "#037171",
                              backgroundColor: "#fff",
                              borderRadius: "5px",
                              margin: "0 5px",
                            }}
                            label={`${currentFacilityName}: ${currentSubscription}`}
                          />
                          {!isEmptyString(subscriptionStatus) && (
                            <Chip
                              sx={{
                                backgroundColor:
                                  String(subscriptionStatus).toLowerCase() ===
                                  "suspended"
                                    ? kodi_theme.palette.warning.main
                                    : kodi_theme.palette.error.main,

                                color: "#fff",
                                borderRadius: "5px",
                                margin: "0 5px",
                              }}
                              label={subscriptionStatus}
                            />
                          )}
                        </ResponsiveRow>
                      </Tooltip>
                    )}
                  </IconButton>
                </Permit>
                <Permit roles="BLDIR,BLDMG,ACC,OWN,REC,CS">
                  <Menu
                    id="facilitySelectorMenu"
                    anchorEl={anchorElFacility}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElFacility)}
                    onClose={() => {
                      detachFacilityAnchor(null);
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "flex-start",
                      height: "3750px",
                      "& .MuiMenu-paper": {
                        position: "relative",
                        height: "100%",
                        width: "300px",
                        overflowY: "hidden",
                      },
                    }}
                  >
                    <ResponsiveRow
                      sx={{
                        flexWrap: "nowrap",
                        width: "100%",
                        height: "50px",
                        overflowY: "hidden",
                        top: 0,
                      }}
                    >
                      <OutlinedInput
                        sx={{
                          borderRadius: "5px",
                          height: "100%",
                          width: "100%",
                          fontSize: ".9rem",
                        }}
                        value={searchQuery}
                        onChange={handleSearchFilter}
                        placeholder="Search facilities"
                      />
                    </ResponsiveRow>
                    <ResponsiveRow
                      sx={{
                        position: "relative",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: "150px",
                        overflowY: "auto",
                      }}
                    >
                      {showSearchResults === true && searchResults !== undefined
                        ? searchResults.map((facility, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => {
                                detachFacilityAnchor(facility.value);
                                setSearchResults([]);
                                setSearchQuery("");
                                setShowSearchResults(false);
                              }}
                            >
                              <Typography
                                color={
                                  selectedFacility === facility.value
                                    ? "secondary"
                                    : "primary"
                                }
                                variant="body2"
                                textAlign="start"
                              >
                                {facility.label}
                              </Typography>
                            </MenuItem>
                          ))
                        : isArray(facilityList) &&
                          facilityList.map((facility, i) => (
                            <MenuItem
                              key={i}
                              onClick={() =>
                                detachFacilityAnchor(facility.value)
                              }
                            >
                              <Typography
                                color={
                                  selectedFacility === facility.value
                                    ? "secondary"
                                    : "primary"
                                }
                                variant="body2"
                                textAlign="start"
                              >
                                {facility.label}
                              </Typography>
                            </MenuItem>
                          ))}
                    </ResponsiveRow>
                    <MenuItem
                      sx={{
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        background: "#efefef",
                        color: "darkgrey",
                        fontSize: ".9rem",
                        fontWeight: 700,
                        height: "45px",
                        width: "100%",
                      }}
                      onClick={() => {
                        setAnchorElFacility(null);
                        setSearchResults([]);
                        setSearchQuery("");
                        setShowSearchResults(false);
                      }}
                    >
                      Cancel
                    </MenuItem>
                  </Menu>
                </Permit>

                <Tooltip title="Profile Actions">
                  <IconButton
                    sx={{ backgroundColor: "#037171" }}
                    aria-label=""
                    aria-controls={openUser ? "userOptionsMenu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openUser ? "true" : undefined}
                    onClick={attachUserAnchorEL}
                  >
                    <Avatar sx={{ backgroundColor: "#037171" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="userOptionsMenu"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={detachUserAnchor}
                  PaperProps={{
                    style: {
                      maxHeight: "300px",
                    },
                  }}
                  sx={{
                    display: "block",
                    justifyContent: "flex-start",
                  }}
                >
                  <MenuItem key="profile" onClick={openUserProfile}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" sx={{ color: "#037171" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: "#037171" }}>
                      Profile
                    </ListItemText>
                  </MenuItem>
                  <MenuItem key="logout" onClick={logoutUser}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" sx={{ color: "#037171" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: "#037171" }}>
                      Log-out
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </ResponsiveRow>
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </>
  );
}
