import { Typography } from "@mui/material";
import {
  DetailsCardContainer,
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../constants/component.constants";
import { formatCurrency, formatDate, isNullUndefined } from "../../util/util";

export default function LeaseInfoCard(props) {
  return (
    <DetailsCardContainer container spacing={1}>
      <DetailsRow>
        <Typography
          fontWeight={800}
          variant="h6"
          color="primary.dark"
          sx={{
            fontSize: "18px",
          }}
        >
          Lease Information for{" "}
          {props.selectedLease && props.selectedLease.contracteeName}
        </Typography>
      </DetailsRow>

      <DetailsContainer sx={{ width: "100%" }}>
        <DetailsColumn
          sx={{
            width: { lg: "25%", md: "100%", xs: "100%" },
          }}
        >
          <Typography
            fontWeight={800}
            variant="h6"
            color="primary.dark"
            sx={{
              textAlign: "start",
              marginBottom: "20px",
            }}
          >
            Lease Information
          </Typography>

          <DetailsRow>
            <DetailsLabel>Property Name</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.facilityName}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Units Leases</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.unitNames}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Lease Type</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.leaseType}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Lease Balance</DetailsLabel>
            <DetailsText>
              {props.selectedLease &&
                formatCurrency(props.selectedLease.leaseBalance)}
            </DetailsText>
          </DetailsRow>
        </DetailsColumn>
        <DetailsColumn
          sx={{
            width: { lg: "20%", md: "100%", xs: "100%" },
          }}
        >
          <Typography
            fontWeight={800}
            variant="h6"
            color="primary.dark"
            sx={{
              textAlign: "start",
              marginBottom: "20px",
            }}
          >
            Lease Status
          </Typography>
          <DetailsRow>
            <DetailsLabel>Effective Date</DetailsLabel>
            <DetailsText>
              {
              props.selectedLease && isNullUndefined(props.selectedLease.start)
                ? "N/A"
                : formatDate(props.selectedLease.start)
              }
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Expiration Date</DetailsLabel>
            <DetailsText>
              {props.selectedLease &&
              isNullUndefined(props.selectedLease.end)
                ? "N/A"
                : formatDate(props.selectedLease.end)}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Status</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.status.name}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Has Revenue Sharing</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.hasRevenueSharing ? "Yes" : "No"}
            </DetailsText>
          </DetailsRow>
        </DetailsColumn>
        <DetailsColumn
          sx={{
            width: { lg: "30%", md: "100%", xs: "100%" },
          }}
        >
          <Typography
            fontWeight={800}
            variant="h6"
            color="primary.dark"
            sx={{
              textAlign: "start",
              marginBottom: "20px",
            }}
          >
            Tenant Details
          </Typography>
          <DetailsRow>
            <DetailsLabel>Tenant</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.contracteeName}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Email</DetailsLabel>
            <DetailsText sx={{ wordBreak: "break-word" }} >
              {props.selectedLease && props.selectedLease.tenant.email}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Phone</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.tenant.mobilePhone}
            </DetailsText>
          </DetailsRow>
        </DetailsColumn>
        <DetailsColumn
          sx={{
            width: { lg: "25%", md: "100%", xs: "100%" },
          }}
        >
          <Typography
            fontWeight={800}
            variant="h6"
            color="primary.dark"
            sx={{
              textAlign: "start",
              marginBottom: "20px",
            }}
          >
            Payment Information
          </Typography>
          <DetailsRow>
            <DetailsLabel>Billing Code</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.billingCode}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Rent Frequency</DetailsLabel>
            <DetailsText>
              {props.selectedLease && props.selectedLease.rentFrequency}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Periodic Charge</DetailsLabel>
            <DetailsText>
              {props.selectedLease &&
                formatCurrency(props.selectedLease.periodicCharge)}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Rent Day/Date</DetailsLabel>
            <DetailsText>
              {props.selectedLease &&
                !isNullUndefined(props.selectedLease.rentDate) &&
                formatDate(props.selectedLease.rentDate)}
              {props.selectedLease &&
                !isNullUndefined(props.selectedLease.rentDay) &&
                props.selectedLease.rentDay}
            </DetailsText>
          </DetailsRow>
        </DetailsColumn>
      </DetailsContainer>
    </DetailsCardContainer>
  );
}
