/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react"
import {GridActionsCellItem} from "@mui/x-data-grid"
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import {Add, Cancel, Clear, Contacts, CreditCard, Edit, LocalAtm, NearMe, Visibility,} from "@mui/icons-material"
import {
    addOwnershipContact,
    bulkEditOwnerships,
    downloadOwnerships,
    exportOwnerships,
    getOwnershipChargeCodes,
    getOwnershipContacts,
    getOwnerships,
    getTotalOwnerships,
    ownershipTermination,
    removeOwnershipContact,
    resendLastOwnershipInvoice,
    resendLastOwnershipReceipt,
    resendOwnershipStatement,
    saveOwnershipData,
    saveOwnershipNote,
    saveOwnershipPayment,
    updateOwnershipData,
} from "../services/ownership.service"
import {personOrgSearch} from "../services/shared.service"
import {getFacilityOptions, getIdTypes, getRentDays, getRentPeriods, getUnitOptions,} from "../services/list.service"
import {
    adjustForTimezone,
    formatCurrency,
    formatDate,
    getDayStart,
    getTitleRefNumber,
    isArray,
    isEmptyArray,
    isEmptyString,
    isNullUndefined,
    isValidDate,
    sortNumeric,
    tryParseFloat,
} from "../util/util"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en-gb"
import {useSelector} from "react-redux"
import {issueResponseMessage, issueWarnMessage} from "../actions/message"
import Permit from "../util/permit"
import PersonOrgSearch from "../components/modals/personOrgSearch"
import AddNewUser from "../components/modals/addNewUser"
import AddNewOrganisation from "../components/modals/addNewOrganisation"
import ReceivePayment from "../components/modals/receivePayment"
import ContractTermination from "../components/contractTermination"
import TransactionHistory from "../components/transactionHistory"
import CreditNote from "../components/modals/creditNote"
import NoteLogsCard from "../components/cards/noteLogsCard"
import {
    DetailsCardContainer,
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
    MainContainer,
    TopBarContainedBrandButton,
    TopBarContainedCancelButton,
    TopBarContainer,
} from "../constants/component.constants"
import DebitNote from "../components/modals/debitNote"
import {DocumentStore} from "../components/documentStore"
import ExportDocumentsModal from "../components/modals/exportDocuments"
import {printOwnership, printOwnershipStatement,} from "../services/print.service"
import {ResponsiveRow} from "../constants/layout.constants"
import CreateOwnershipModal from "../components/modals/homes/ownerships/createOwnershipModal"
import AddNoteModal from "../components/modals/homes/ownerships/addNoteModal"
import BulkEditOwnershipsModal from "./homeOwners/Ownerships/BulkEditOwnershipsModal"
import OwnershipChargesCard from "../components/cards/ownershipChargesCard"
import {renderCellExpand} from "../util/renderCellExpand"
import ConfirmationModal from "../components/modals/confirmation"
import SplitButton from "../components/splitButton"
import ContractContactModal from "../components/modals/contractContactModal"
import {saveNewContactPerson} from "../services/users.service"
import dayjs from "dayjs"
import MuiDataGrid from "../components/modules/MuiDataGrid"

export default function Ownership() {
  const [AddOrganizationModal, setAddOrganizationModal] = useState(false)
  const [addUserModal, setAddUserModal] = useState(false)
  const [ownerships, setOwnerships] = useState([])
  const [searchReference, setSearchReference] = useState("")
  const [searchOwner, setSearchOwner] = useState("")
  const [searchUnit, setSearchUnit] = useState("")
  const [loading, setLoading] = useState(true)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  })
  const [totalOwnerships, setTotalOwnerships] = useState(0)
  const [paymentModal, setPaymentModal] = useState(false)
  const [terminateItem, setTerminateItem] = useState(null)
  const [creditModal, setCreditModal] = useState(false)
  const handlePaymentClose = () => setPaymentModal(false)
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [balance, setBalance] = useState(0)
  const [balanceNotes, setBalanceNotes] = useState(0)
  const [selectedOwnership, setSelectedOwnership] = useState(null)
  const [selectedOwnerships, setSelectedOwnerships] = useState([])
  const [selectedUnit, setSelectedUnit] = useState("")
  const [statementYear, setStatementYear] = useState(new Date())
  const [chargeItemsTotal, setChargeItemsTotal] = useState(0)
  const [homeOwners, setHomeOwners] = useState([])
  const [chargeItems, setChargeItems] = useState([])
  const [homeOwnersTableRows, setHomeOwnersTableRows] = useState("")
  const [unitDetailTableRows, setUnitDetailTableRows] = useState("")
  const [contactTableRows, setContactTableRows] = useState("")
  const [ownershipChargesTableRows, setOwnershipChargesTableRows] =
    useState("")
  const [invoiceTypes, setInvoiceTypes] = useState([])
  const [chargeModal, setChargeModal] = useState(false)
    const [noteModal, setNoteModal] = useState(false)
    const [downloadModal, setDownloadModal] = useState(false)
    const [terminateModal, setTerminateModal] = useState(false)
    const [tenantOccupied, setTenantOccupied] = useState(false)
    const [includeOwnershipDetail, setIncludeOwnershipDetail] = useState(false)
    const [note, setNote] = useState("")
    const [task, setTask] = useState("")
    const [billingPeriods, setBillingPeriods] = useState([])
    const [rentDays, setRentDays] = useState([])
    const [ownershipChargeCodes, setOwnershipChargeCodes] = useState([])
    const [idTypes, setIdTypes] = useState([])
    const [facilityOptions, setFacilityOptions] = useState([])
    const [unitOptions, setUnitOptions] = useState([])
    const [tenantModal, setTenantModal] = useState(false)
    const [contactModal, setContactModal] = useState(false)
    const [isTenantSelect, setIsTenantSelect] = useState(false)
    const [isHomeOwnerSelect, setIsHomeOwnerSelect] = useState(false)
    const [isContactSelect, setIsContactSelect] = useState(false)
    const [idTypeSearch, setIdType] = useState([])
    const [idNoSearch, setIdNo] = useState("")
    const [companySearch, setCompanySearch] = useState("")
    const [searchBy, setSearchBy] = useState("")
    const [foundTenant, setFoundTenant] = useState([])
    const user = useSelector((state) => state.auth.user)
    const [rowSelectionModel, setRowSelectionModel] = useState([])
    const [ownershipContacts, setOwnershipContacts] = useState([])
    const [titleText, setTitleText] = useState("")
    const [severityText, setSeverityText] = useState("")
    const [bodyText, setBodyText] = useState("")
    const [positiveText, setPositiveText] = useState("")
    const [negativeText, setNegativeText] = useState("")
    const [voiditem, setVoiditem] = useState(null)
    const [showAddNewContact, setShowAddNewContact] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [bulkChargeAction, setBulkChargeAction] = useState(false)

    const resendButtons = [
        {
            label: "RESEND ACTIONS",
            roles: false,
            services: false,
        },
        {
            action: () => handleSendOwnershipDocClick("Invoice"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Invoice"
                    : "Resend Last Invoice",
            roles: "BLDIR,BLDMG,CS",
            services: "",
            disabled: rowSelectionModel.length < 1,
        },
        {
            action: () => handleSendOwnershipDocClick("Receipt"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Receipts"
                    : "Resend Last Receipt",
            roles: "BLDIR,BLDMG,CS",
            services: "",
            disabled: rowSelectionModel.length < 1,
        },
        {
            action: () => handleSendOwnershipDocClick("Statement"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Statements"
                    : "Resend Last Statement",
            roles: "BLDIR,BLDMG,CS",
            services: "",
            disabled: rowSelectionModel.length < 1,
        },
    ]
    const ownershipButtons = [
        {
            label: "OWNERSHIP ACTIONS",
            roles: false,
            services: false,
        },
        {
            action: handleBulkEditOwnershipClick,
            label: "Bulk Ownership Edit",
            roles: false,
            services: false,
            disabled: rowSelectionModel.length <= 1,
        },
        {
            action: bulkCharge,
            label:
                rowSelectionModel.length > 1
                    ? "Issue Charges to " + rowSelectionModel.length + " Ownerships"
                    : "Issue Charge",
            roles: false,
            services: false,
            disabled: rowSelectionModel.length <= 1,
        },
        {
            action: handleDownloadClick,
            label:
                rowSelectionModel.length > 1
                  ? "Export " + rowSelectionModel.length + " Ownerships Statements"
                  : "Export Ownership Statement",
            roles: false,
            services: false,
            disabled: rowSelectionModel.length <= 1,
        },
        {
            action: exportOwnershipData,
            label: "Download All Ownerships",
            roles: false,
            services: false,
        },
    ]

    useMemo(() => {
        setLoading(true)
        getTotalOwnerships(searchReference, searchOwner, searchUnit).then(
            (response) => {
                setTotalOwnerships(response.totalTitles)
            }
        )
        getOwnerships(
            searchReference,
            searchOwner,
            searchUnit,
            paginationModel.page,
            paginationModel.pageSize
        )
            .then((ownerships) => {
                setOwnerships(ownerships)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [
        searchReference,
        searchOwner,
        searchUnit,
        paginationModel.page,
        paginationModel.pageSize,
    ])

    function fetchOwnership() {
        if (
            !isNullUndefined(selectedOwnership) &&
            !isNullUndefined(selectedOwnership.id)
        ) {
            setLoading(true)
            getOwnershipContacts(selectedOwnership.id)
                .then((ownershipContacts) => {
                    if (!isNullUndefined(ownershipContacts))
                        setOwnershipContacts(ownershipContacts)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        if (
            !isNullUndefined(selectedOwnership) &&
            !isNullUndefined(selectedOwnership.facilityId)
        ) {
            if (task === "Edit") {
                let unitArray = []
                let unit = {
                    value: selectedOwnership.unit.id,
                    label: selectedOwnership.unit.name,
                }
                unitArray.push(unit)
                setUnitOptions(unitArray)
                populateHomeOwnerChargeItems()
            } else {
                getUnitOptions(selectedOwnership.facilityId).then((unitOptions) =>
                    setUnitOptions(unitOptions)
                )
            }
        }
    }

    useMemo(() => {
        fetchOwnership()
    }, [selectedOwnership])

    useEffect(() => {
        getRentPeriods().then((billingPeriods) =>
            setBillingPeriods(billingPeriods)
        )
        getRentDays().then((rentDays) => setRentDays(rentDays))
        getOwnershipChargeCodes().then((ownershipChargeCodes) =>
            setOwnershipChargeCodes(ownershipChargeCodes)
        )
        if (idTypes.length === 0) {
            getIdTypes().then((idTypes) => setIdTypes(idTypes))
        }
        getFacilityOptions()
            .then((facilityOptions) => setFacilityOptions(facilityOptions))

    }, [])

    useMemo(() => {
        populateHomeOwnerItems()
    }, [homeOwners])

    useMemo(() => {
        let rows = ""
        ownershipContacts &&
        ownershipContacts.length > 0 &&
        (rows = ownershipContacts.map((contact, i) => {
            let phone = contact.mobilePhone
            return (
                <TableRow key={i}>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>{phone}</TableCell>
                    <TableCell>{contact.email}</TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={() =>
                                handleOwnershipContactRemove(contact.personId)
                            }
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }))
        setContactTableRows(rows)
    }, [ownershipContacts])

    useMemo(() => {
        let rows = ""
        selectedOwnerships &&
        selectedOwnerships.length > 0 &&
        (rows = selectedOwnerships.map((ownership, i) => {
            return (
                <TableRow key={i}>
                    <TableCell>{ownership.unitName}</TableCell>
                    <TableCell>{ownership.ownerNames}</TableCell>
                </TableRow>
            )
        }))
        setUnitDetailTableRows(rows)
    }, [selectedOwnerships])

    useMemo(() => {
        if (!terminateModal && !chargeModal) {
            populateHomeOwnerChargeItems()
        }
    }, [chargeItems])

    const handleCreditClose = () => {
        setCreditModal(false)
        if (isNullUndefined(voiditem)) {
            //update the list
            getOwnerships(
                searchReference,
                searchOwner,
                searchUnit,
                paginationModel.page,
                paginationModel.pageSize
            ).then((ownerships) => setOwnerships(ownerships))
        } else {
            // update one ownership
            fetchOwnership()
        }
        setVoiditem(null)
    }

    function handleChargeClose() {
        setChargeModal(false)
        if (isNullUndefined(voiditem)) {
            //update the list
            getOwnerships(
                searchReference,
                searchOwner,
                searchUnit,
                paginationModel.page,
                paginationModel.pageSize
            ).then((ownerships) => setOwnerships(ownerships))
        } else {
            // update one ownership
            fetchOwnership()
        }
        setVoiditem(null)
        setBulkChargeAction(false)
    }

    const handleContactClose = () => {
        setIsContactSelect(false)
        setSearchBy("")
        setIdType("")
        setIdNo("")
        setFoundTenant([])
        setContactModal(false)
        setShowAddNewContact(false)
        setOwnershipContacts([])
    }

    const handleContactClear = () => {
        setIdType("")
        setIdNo("")
        setFoundTenant([])
    }

    const handleNoteClose = () => {
        setNote("") //Clear note
        setNoteModal(false)
    }

    const handleDownloadClose = () => {
        setRowSelectionModel([]) //Clear selection
        setStatementYear(new Date())
        setIncludeOwnershipDetail(false)
        setDownloadModal(false)
        setTitleText("")
        setTask("")
        setSeverityText("")
        setBodyText("")
    }

    const handleTerminateClose = () => {
        setChargeItems([]) //Clear items
        setTerminateModal(false)
    }

    const handleAddOwnershipClose = () => {
        setSelectedOwnership(null)
        setTenantOccupied(false)
        setChargeItemsTotal(0)
        setChargeItems([])
        setHomeOwners([])
        setSelectedUnit("")
        setStartDate(null)
        setEndDate(null)
        setTask("")
    }

    const handleBulkEditOwnershipClose = () => {
        setSelectedOwnerships([])
        setChargeItemsTotal(0)
        setChargeItems([])
        setRowSelectionModel([])
        setTask("")
    }

    const handlePersonOrgClose = () => {
        setTenantModal(false)
    }

    function searchPersonOrg() {
        let data = {
            searchType: searchBy,
            idType: idTypeSearch,
            idNumber: idNoSearch,
            organizationName: companySearch,
        }
        personOrgSearch(data).then((response) => {
            if (
                !isNullUndefined(response) &&
                isArray(response) &&
                !isEmptyArray(response)
            ) {
                setShowAddNewContact(false)
            } else {
                setShowAddNewContact(true)
            }
            setFoundTenant(response)
        })
    }

    function handlePersonOrgSelect(result) {
        if (isTenantSelect) {
            let obj = selectedOwnership
            obj.tenant = result
            setSelectedOwnership(obj)
            setTenantModal(false)
            setIsHomeOwnerSelect(false)
            setIsTenantSelect(false)
            setIsContactSelect(false)
            setSearchBy("")
            setIdType("")
            setIdNo("")
            setCompanySearch("")
            setFoundTenant([])
        }

        if (isHomeOwnerSelect) {
            let invoiced = false
            if (homeOwners.length < 1) {
                invoiced = true
            }
            let owner = result
            owner.invoiced = invoiced
            setHomeOwners((homeOwners) => [...homeOwners, owner])
            let obj = selectedOwnership
            obj.titleOwners.push(owner)
            setSelectedOwnership(obj)
            setTenantModal(false)
            setIsHomeOwnerSelect(false)
            setIsTenantSelect(false)
            setIsContactSelect(false)
            setSearchBy("")
            setIdType("")
            setIdNo("")
            setCompanySearch("")
            setFoundTenant([])
        }

        if (isContactSelect) {
            setTenantModal(false)
            setIsHomeOwnerSelect(false)
            setIsTenantSelect(false)
            setCompanySearch("")
            if (showAddNewContact) {
                saveNewContactPerson(result).then((response) => {
                    let data = {
                        titleId: selectedOwnership.id,
                        personId: response.person.id,
                    }
                    if (validateAddOwnershipContact(data)) {
                        addOwnershipContact(data).then((response) => {
                            issueResponseMessage(response)
                            setShowAddNewContact(false)
                            getOwnershipContacts(selectedOwnership.id).then(
                                (ownershipContacts) => setOwnershipContacts(ownershipContacts)
                            )
                            handleContactClear()
                        })
                    }
                })
            } else {
                let data = {
                    titleId: selectedOwnership.id,
                    personId: result.id,
                }
                if (validateAddOwnershipContact(data)) {
                    addOwnershipContact(data).then((response) => {
                        issueResponseMessage(response)
                        getOwnershipContacts(selectedOwnership.id).then(
                            (ownershipContacts) => setOwnershipContacts(ownershipContacts)
                        )
                        handleContactClear()
                    })
                }
            }
        }
    }

    function handleIdTypeChange(event) {
        let value = event.target.value
        setIdType(value)
    }

    function onTextChange(event) {
        const id = event.target.id
        const value = event.target.value
        if (id === "searchRef") {
            setSearchReference(value)
        }
        if (id === "searchUnit") {
            setSearchUnit(value)
        }
        if (id === "searchOwner") {
            setSearchOwner(value)
        }
        if (id === "note") {
            setNote(value)
        }
        if (id === "idNoSearch") {
            setIdNo(value)
        }
        if (id === "companySearch") {
            setCompanySearch(value)
        }
    }

    function handleChargeBillingPeriodChange(event, index) {
        let value = event.target.value
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].billingPeriod = value
        setChargeItems(newArr)
        populateHomeOwnerChargeItems()
    }

    function handleInvoiceDayChange(event, index) {
        let value = event.target.value
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].invoiceDay = value
        setChargeItems(newArr)
        populateHomeOwnerChargeItems()
    }

    function handleInvoiceDateChange(value, index) {
        let date = dayjs(value)
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].invoiceDate = date
        setChargeItems(newArr)
        populateHomeOwnerChargeItems()
    }

    function handleChargeTaxableToggle(event, index) {
        let value = event.target.checked
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].taxable = value
        setChargeItems(newArr)
        populateHomeOwnerChargeItems()
    }

    function handleHomeOwnerChargeItemRemove(index) {
        let newArr = [...chargeItems] // copying the old items array
        newArr.splice(index, 1)
        setChargeItems(newArr)
        populateHomeOwnerChargeItems()
    }

    function handleChargeCodeChange(event, index) {
        let value = event.target.value
        let name
        if (value === "CAPF") {
            name = "Capital Fund"
        } else if (value === "SERCH") {
            name = "Service Charge"
        } else if (value === "ELCUT") {
            name = "Utility - Electricity"
        } else if (value === "WTUT") {
            name = "Utility - Water"
        }
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].chargeCode.code = value
        newArr[index].chargeCode.name = name
        setChargeItems(newArr)
        populateHomeOwnerChargeItems()
    }

    function ownershipChargeAmountChange(event, index) {
        let value = event.target.value
        let newArr = [...chargeItems] // copying the old items array
        newArr[index].amount = value
        setChargeItems(newArr)
        populateHomeOwnerChargeItems()
    }

    function handleOwnershipChargeItemAdd() {
        let charge = {
            amount: "",
            chargeCode: {code: "", name: ""},
            taxable: false,
            invoiceDate: "",
            billingPeriod: "",
            invoiceDay: "",
        }
        setChargeItems((chargeItems) => [...chargeItems, charge])
    }

    function handleInvoicedHomeownerToggle(event, index) {
        let newArr = [...homeOwners] // copying the old items array
        let obj = selectedOwnership
        if (event.target.checked) {
            let updatedItems = newArr.map(function (owner, num) {
                owner.invoiced = num === index;
                return owner
            })
            obj.titleOwners = updatedItems
            setHomeOwners(updatedItems)
            setSelectedOwnership(obj)
        }else{
            newArr[index].invoiced = false
            obj.titleOwners = newArr
            setHomeOwners(newArr)
            setSelectedOwnership(obj)
        }
    }

    function handleTenantSwitchToggle(event) {
        let obj = selectedOwnership
        obj.tenantOccupied = event.target.checked
        setSelectedOwnership(obj)
        setTenantOccupied(event.target.checked)
    }

    function handleIncludeOwnershipDetailToggle(event) {
        setIncludeOwnershipDetail(event.target.checked)
    }

    function handleHomeOwnerItemRemove(index) {
        let newArr = [...homeOwners] // copying the old items array
        if (newArr.length > 1) {
            newArr.splice(index, 1)
            let obj = selectedOwnership
            obj.titleOwners = newArr
            setHomeOwners(newArr)
            setSelectedOwnership(obj)
        } else {
            issueWarnMessage("There must be at least one home owner")
        }
    }

    function handleOwnershipContactRemove(personId) {
        if (
            isNullUndefined(selectedOwnership) ||
            isNullUndefined(selectedOwnership.id) ||
            isEmptyString(selectedOwnership.id)
        ) {
            issueWarnMessage(
                "Something went wrong: couldn't find the title to retrieve the contacts. Try refresh the page."
            )
            return
        }
        if (isNullUndefined(personId) || isEmptyString(personId)) {
            issueWarnMessage(
                "Couldn't find the person identifier. Try refresh the page"
            )
            return
        }
        let data = {
            titleId: selectedOwnership.id,
            personId: personId,
        }
        removeOwnershipContact(data).then((response) => {
            issueResponseMessage(response)
            getOwnershipContacts(selectedOwnership.id).then((ownershipContacts) =>
                setOwnershipContacts(ownershipContacts)
            )
            handleContactClear()
        })
    }

    function handlePaymentClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setPaymentModal(true)
    }

    function handleTerminateClick(selectedOwnership) {
        let data = {
            titleId: selectedOwnership.id,
            paymentMethod: "", //CASH/CHEQUE - only needed if final balance < 0
            cashAccountId: "", //When Cash, must select which account
            chequeNumber: "", // When Cheque
            bankRefNumber: "", // For RTGS payments
            charges: [],
        }
        setTerminateItem(data)
        setChargeItems([])
        setSelectedOwnership(selectedOwnership)
        setTerminateModal(true)
    }

    function handleNoteClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setNoteModal(true)
    }

    function handleCreditClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setCreditModal(true)
    }

    function handleContactClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setContactModal(true)
        setIsContactSelect(true)
        setSearchBy("Individual")
    }

    function handleChargeClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setChargeModal(true)
    }

    function handleUnitChange(event) {
        let value = event.target.value
        let obj = selectedOwnership
        obj.unitId = value
        setSelectedOwnership(obj)
        setSelectedUnit(value)
        populateHomeOwnerChargeItems()
    }

    function handleBalanceChange(event) {
        let value = event.target.value
        let obj = selectedOwnership
        obj.balance = value
        setSelectedOwnership(obj)
        setBalance(value)
    }

    function handleBalanceNotesChange(event) {
        let value = event.target.value
        let obj = selectedOwnership
        obj.balanceNotes = value
        setSelectedOwnership(obj)
        setBalanceNotes(value)
    }

    function handleStatementYearChange(value) {
        let date = dayjs(value)
        setStatementYear(date)
    }

    function handleViewClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setTask("View")
    }

    function handleAddOwnershipClick() {
        let facilityId = null
        if (user.proxyFacility != null) {
            facilityId = user.proxyFacility
        }
        let refNumber = getTitleRefNumber()
        let data = {
            id: null,
            titleNo: "",
            startDate: "",
            balance: 0.0,
            balanceNotes: "",
            endDate: null,
            owner: {
                id: null,
                name: null,
            },
            tenant: {
                id: null,
                name: null,
            },
            titleOwners: [],
            facilityId: facilityId,
            unitId: null,
            billingCode: refNumber,
            titleChargeItems: [],
            tenantOccupied: false,
        }
        setSelectedOwnership(data)
        setBalance(0)
        setBalanceNotes("")
        setTask("Create")
    }

    function handleEditOwnershipClick(selectedOwnership) {
        let unit = {
            id: selectedOwnership.unitId,
            name: selectedOwnership.unitName,
        }
        let data = {
            id: selectedOwnership.id,
            titleNo: "",
            startDate: selectedOwnership.startDate,
            endDate: selectedOwnership.endDate,
            tenant: selectedOwnership.tenant,
            titleOwners: selectedOwnership.titleOwners,
            facilityId: selectedOwnership.facilityId,
            unitId: selectedOwnership.unitId,
            billingCode: selectedOwnership.billingCode,
            titleChargeItems: selectedOwnership.titleChargeItems,
            tenantOccupied: selectedOwnership.tenantOccupied,
            unit: unit,
        }
        setSelectedUnit(data.unitId)
        setSelectedOwnership(data)
        setHomeOwners(data.titleOwners)
        setChargeItems(data.titleChargeItems)
        setTenantOccupied(data.tenantOccupied)
        setTask("Edit")
    }

    function handleBulkEditOwnershipClick() {
        let ownershipsToEdit = []
        rowSelectionModel.forEach((value) => {
            ownerships.forEach((ownership) => {
                if (ownership.id === value) {
                    ownershipsToEdit.push(ownership)
                }
            })
        })
        setSelectedOwnerships(ownershipsToEdit)
        setTask("BulkEdit")
    }

    function savePayment(data) {
        data.titleId = selectedOwnership.id
        saveOwnershipPayment(data).then((response) => {
            issueResponseMessage(response)
            if (!isNullUndefined(response.title)) {
                getOwnerships(
                    searchReference,
                    searchOwner,
                    searchUnit,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((ownerships) => setOwnerships(ownerships))
                handlePaymentClose()
            }
        })
    }

    function saveOwnership() {
        let obj = selectedOwnership
        obj.titleChargeItems = chargeItems
        obj.titleOwners = [...homeOwners]
        setSelectedOwnership(obj)

        if (validateSaveOwnershipForm()) {
            saveOwnershipData(selectedOwnership).then((response) => {
                issueResponseMessage(response)
                getOwnerships(
                    searchReference,
                    searchOwner,
                    searchUnit,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((ownerships) => setOwnerships(ownerships))
                getTotalOwnerships(searchReference, searchOwner, searchUnit).then(
                    (ownerships) => setTotalOwnerships(ownerships.totalTitles)
                )
                handleAddOwnershipClose()
            })
            handleAddOwnershipClose()
        }
    }

    function bulkEditOwnershipClick() {
        if (isNullUndefined(selectedOwnerships) || selectedOwnerships.length < 1) {
            issueWarnMessage(
                "Could not determine the bulk edit items. Please refresh the page and try again."
            )
            return
        }
        let data = {
            bulkEditIds: rowSelectionModel,
            bulkEditCharges: chargeItems,
        }
        bulkEditOwnerships(data).then((response) => {
            getOwnerships(
                searchReference,
                searchOwner,
                searchUnit,
                paginationModel.page,
                paginationModel.pageSize
            ).then((ownerships) => setOwnerships(ownerships))
            handleBulkEditOwnershipClose()
            issueResponseMessage(response)
        })
    }

    function updateOwnership() {
        let obj = selectedOwnership
        obj.titleChargeItems = chargeItems
        obj.titleOwners = [...homeOwners]
        obj.balance = 0
        setSelectedOwnership(obj)

        if (validateSaveOwnershipForm()) {
            updateOwnershipData(selectedOwnership).then((response) => {
                issueResponseMessage(response)
                getOwnerships(
                    searchReference,
                    searchOwner,
                    searchUnit,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((ownerships) => setOwnerships(ownerships))
                getTotalOwnerships(searchReference, searchOwner, searchUnit).then(
                    (ownerships) => setTotalOwnerships(ownerships.totalTitles)
                )
                handleAddOwnershipClose()
            })
        }
    }

    function exportOwnershipData() {
        exportOwnerships()
        setRowSelectionModel([])
    }

    function bulkCharge() {
        setChargeModal(true)
        setBulkChargeAction(true)
    }

    function handleSendOwnershipDocClick(docType) {
        let title = "Confirm Send " + docType
        let body =
            "Are you sure you want to resend the last " +
            docType +
            " for " +
            rowSelectionModel.length +
            " selected ownership(s)?"
        setTitleText(title)
        setTask("Resend " + docType)
        setSeverityText("warning")
        setBodyText(body)
        setPositiveText("Send")
        setNegativeText("Cancel")
        setShowConfirmationModal(true)
    }

    const handleConfirmationClose = () => {
        setShowConfirmationModal(false)
        setTitleText("")
        setTask("")
        setSeverityText("")
        setBodyText("")
        setPositiveText("")
        setNegativeText("")
        setRowSelectionModel([])
    setInvoiceTypes([])
  }

  function resendLastInvoiceClicked() {
    let sendData = {
      bulkEditIds: rowSelectionModel,
      invoiceTypes: invoiceTypes
    }
    resendLastOwnershipInvoice(sendData).then((response) => {
      issueResponseMessage(response)
      handleConfirmationClose()
    })
  }

    function resendLastReceiptClicked() {
        let sendData = {bulkEditIds: rowSelectionModel}
        resendLastOwnershipReceipt(sendData).then((response) => {
            issueResponseMessage(response)
            handleConfirmationClose()
        })
    }

    function resendStatementClicked() {
        let sendData = {bulkEditIds: rowSelectionModel}
        resendOwnershipStatement(sendData).then((response) => {
            issueResponseMessage(response)
            handleConfirmationClose()
        })
    }

    function positiveAction() {
        if (task === "Resend Invoice") {
            resendLastInvoiceClicked()
        } else if (task === "Resend Receipt") {
            resendLastReceiptClicked()
        } else if (task === "Resend Statement") {
            resendStatementClicked()
        }
    }

    function handleDownloadClick() {
        setDownloadModal(true)
        let title =
            rowSelectionModel.length > 1
                ? "Download " + rowSelectionModel.length + " Ownerships"
                : "Download Ownership"
        let body =
            "Note: Processing the selected ownerships may take some time." +
            " Therefore, the exported data will be emailed to you when it is finished being packaged." +
            " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data."
        setTitleText(title)
        setSeverityText("info")
        setBodyText(body)
    }

    function positiveExportDocsAction() {
        downloadOwnershipDocs()
    }

    function downloadOwnershipDocs() {
        if (isNullUndefined(rowSelectionModel) || rowSelectionModel.length < 1) {
            issueWarnMessage(
                "Could not determine the download items. Please refresh the page and try again."
            )
            return
        }
        let data = {
            titleIds: rowSelectionModel,
            includeTitleDetails: includeOwnershipDetail,
            statementYear: dayjs(statementYear).year(), // e.g. 2022
        }
        downloadOwnerships(data).then((response) => {
            issueResponseMessage(response)
            handleDownloadClose()
        })
    }

    function handleStartDateChange(value) {
        let date = dayjs(value)
        let obj = selectedOwnership
        obj.startDate = date
        setStartDate(date)
        setSelectedOwnership(obj)
    }

    function handleEndDateChange(value) {
        let date = dayjs(value)
        let obj = selectedOwnership
        obj.endDate = date
        setEndDate(date)
        setSelectedOwnership(obj)
    }

    function populateHomeOwnerItems() {
        let rows = ""
        homeOwners &&
        homeOwners.length > 0 &&
        (rows = homeOwners.map(function (homeOwner, i) {
            let phone = homeOwner.mobilePhone
            if (
                homeOwner.type === "Company" &&
                (!isNullUndefined(homeOwner.organizationContacts) ||
                    !isNullUndefined(homeOwner.contact))
            ) {
                if (!isEmptyArray(homeOwner.organizationContacts)) {
                    phone = homeOwner.organizationContacts[0].mobilePhone
                }
                if (!isNullUndefined(homeOwner.contact)) {
                    phone = homeOwner.contact.mobilePhone
                }
            }
            return (
                <TableRow key={i}>
                    <TableCell>{homeOwner.name}</TableCell>
                    <TableCell>{homeOwner.type}</TableCell>
                    <TableCell>{phone}</TableCell>
                    <TableCell>
                        {
                            <Switch
                                checked={homeOwner && homeOwner.invoiced}
                                onChange={(event) => handleInvoicedHomeownerToggle(event, i)}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        }
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={() => handleHomeOwnerItemRemove(i)}
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }, this))
        setHomeOwnersTableRows(rows)
    }

    function populateHomeOwnerChargeItems() {
        let rows = ""
        let total = 0
        chargeItems &&
        chargeItems.length > 0 &&
        (rows = chargeItems.map(function (charge, i) {
            let amount = tryParseFloat(charge.amount, -1)
            if (amount > 0) {
                total = total + amount
            }
            return (
                <TableRow key={i}>
                    {task !== "BulkEdit" && (
                        <TableCell>
                            {task !== "Edit" && (
                                <Select
                                    sx={{width: "90%"}}
                                    id={"unit"}
                                    value={selectedUnit}
                                    disabled={true}
                                    onChange={(event) => handleUnitChange(event)}
                                >
                                    {unitOptions && unitOptions.length > 0 ? (
                                        unitOptions.map(function (unit, i) {
                                            return (
                                                <MenuItem key={unit.value} value={unit.value}>
                                                    {unit.label}
                                                </MenuItem>
                                            )
                                        }, this)
                                    ) : (
                                        <MenuItem sx={{width: "100%"}}>
                                            No Results Found
                                        </MenuItem>
                                    )}
                                </Select>
                            )}
                            {task === "Edit" && selectedOwnership.unit.name}
                        </TableCell>
                    )}
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"chargeCode" + i}
                            value={charge.chargeCode.code}
                            onChange={(event) => handleChargeCodeChange(event, i)}
                        >
                            {ownershipChargeCodes && ownershipChargeCodes.length > 0 ? (
                                ownershipChargeCodes.map(function (chargeCode, i) {
                                    let disabled = false
                                    for (let chargeValue of chargeItems) {
                                        if (chargeValue.chargeCode.code === chargeCode.value) {
                                            disabled = true
                                        }
                                    }
                                    return (
                                        <MenuItem
                                            key={chargeCode.value}
                                            disabled={disabled}
                                            value={chargeCode.value}
                                        >
                                            {chargeCode.label}
                                        </MenuItem>
                                    )
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeAmount" + i}
                            name={"chargeAmount" + i}
                            isrequired={true}
                            disabled={false}
                            value={charge.amount}
                            onChange={(event) => ownershipChargeAmountChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"billingPeriodCode"}
                            value={charge.billingPeriod}
                            onChange={(event) => handleChargeBillingPeriodChange(event, i)}
                        >
                            {billingPeriods && billingPeriods.length > 0 ? (
                                billingPeriods.map(function (billingPeriod, i) {
                                    return (
                                        <MenuItem
                                            key={billingPeriod.value}
                                            value={billingPeriod.value}
                                        >
                                            {billingPeriod.label}
                                        </MenuItem>
                                    )
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        {charge.billingPeriod === "MNTH" && (
                            <Select
                                sx={{width: "90%"}}
                                id={"invoiceDay" + i}
                                value={charge.invoiceDay}
                                onChange={(event) => handleInvoiceDayChange(event, i)}
                            >
                                {rentDays && rentDays.length > 0 ? (
                                    rentDays.map(function (day, i) {
                                        return (
                                            <MenuItem key={day.value} value={day.value}>
                                                {day.label}
                                            </MenuItem>
                                        )
                                    }, this)
                                ) : (
                                    <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                                )}
                            </Select>
                        )}
                        {(charge.billingPeriod === "QT" ||
                            charge.billingPeriod === "ANU") && (
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    sx={{width: "90%"}}
                                    id={"invoiceDate"}
                                    value={dayjs(charge.invoiceDate)}
                                    isrequired={true}
                                    disabled={false}
                                    onChange={(newDate) => handleInvoiceDateChange(newDate, i)}

                                />
                            </LocalizationProvider>
                        )}
                    </TableCell>
                    <TableCell>
                        {
                            <Switch
                                checked={charge.taxable}
                                onChange={(event) => handleChargeTaxableToggle(event, i)}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        }
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={() => handleHomeOwnerChargeItemRemove(i)}
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }, this))
        setOwnershipChargesTableRows(rows)
        setChargeItemsTotal(total)
    }

    function validateAddOwnershipContact(data) {
        if (isNullUndefined(data.titleId) || isEmptyString(data.titleId)) {
            issueWarnMessage(
                "Something went wrong: couldn't find the ownership to retrieve the contacts. Try refresh the page."
            )
            return false
        }
        if (isNullUndefined(data.personId) || isEmptyString(data.personId)) {
            issueWarnMessage(
                "Couldn't find the person identifier. Try refresh the page"
            )
            return false
        }
        let personId = data.personId.replace("_P", "")
        let duplicateContact = ownershipContacts.find((contact) => {
            return contact.personId === personId
        })
        if (!isNullUndefined(duplicateContact)) {
            issueWarnMessage(
                "Cannot add a contact that has already been linked to this ownership"
            )
            return false
        }
        return true
    }

    function handleVoidClick(item) {
        if (item.type === "Charge" || item.type === "Invoice") {
            setCreditModal(true)
            //set the id of the transaction being voided
            setVoiditem(item)
        } else if (item.type === "Receipt" || item.type === "Credit") {
            setChargeModal(true)
            setVoiditem(item)
        }
    }

    function validateSaveOwnershipForm() {
        if (isNullUndefined(selectedOwnership)) {
            issueWarnMessage("Could not determine ownership to save")
            return false
        }

        let balance = tryParseFloat(selectedOwnership.balance.toString(), "error")
        if (balance === "error") {
            issueWarnMessage(
                "Incoming balance should be a value and not '" +
                selectedOwnership.balance.toString() +
                "'"
            )
            return false
        }
        selectedOwnership.balance = balance
        let titleStartDate = new Date(selectedOwnership.startDate)
        if (!isValidDate(titleStartDate)) {
            issueWarnMessage("Ownership start date is not a valid date!!")
            return false
        }

        if (isNullUndefined(selectedOwnership.unitId)) {
            issueWarnMessage("Ownership unit must be selected!!")
            return false
        }

        if (
            !isNullUndefined(selectedOwnership.endDate) &&
            isValidDate(selectedOwnership.endDate)
        ) {
            if (selectedOwnership.endDate <= getDayStart(new Date())) {
                issueWarnMessage("Ownership end date cannot be before today")
                return false
            }
            if (selectedOwnership.endDate <= selectedOwnership.startDate) {
                issueWarnMessage(
                    "Ownership end date cannot be before the ownership start date"
                )
                return false
            }
            selectedOwnership.endDate = adjustForTimezone(selectedOwnership.endDate)
        }

        if (selectedOwnership.titleChargeItems.length < 1) {
            issueWarnMessage(
                "You must add at least one ownership charge to save an ownership."
            )
            return false
        }
        if (isArray(selectedOwnership.titleChargeItems)) {
            for (let charge of selectedOwnership.titleChargeItems) {
                if (
                    isEmptyString(charge.chargeCode.code) ||
                    isEmptyString(charge.chargeCode.name)
                ) {
                    issueWarnMessage(
                        "Could not determine the charge type for one of the ownership charges."
                    )
                    return false
                }
                let amount = tryParseFloat(charge.amount.toString(), -1)
                if (amount < 0) {
                    let message =
                        "Amount '" +
                        charge.amount +
                        "' for charge " +
                        charge.chargeCode.name +
                        " is invalid. Cannot be below 0"
                    if (
                        !isNullUndefined(charge.chargeCode) &&
                        !isEmptyString(charge.chargeCode.name)
                    ) {
                        message =
                            "Amount for charge " +
                            charge.chargeCode.name +
                            " cannot be below 0"
                    }
                    issueWarnMessage(message)
                    return false
                }
                if (isNullUndefined(charge.billingPeriod)) {
                    issueWarnMessage(
                        "Could not determine the billing period for charge: " +
                        charge.chargeCode.name
                    )
                    return false
                }

                if (
                    (charge.billingPeriod === "WEEK" ||
                        charge.billingPeriod === "MNTH") &&
                    (charge.invoiceDay === 0 || isNaN(charge.invoiceDay))
                ) {
                    issueWarnMessage(
                        "Could not determine the invoice day for charge: " +
                        charge.chargeCode.name
                    )
                    return false
                }
                charge.invoiceDay = parseInt(charge.invoiceDay)
                if (
                    (charge.billingPeriod === "QT" || charge.billingPeriod === "ANU") &&
                    (!isValidDate(new Date(charge.invoiceDate)) ||
                        isNullUndefined(charge.invoiceDate))
                ) {
                    issueWarnMessage(
                        "Could not determine the ownership invoice date for charge: " +
                        charge.chargeCode.name
                    )
                    return false
                }
            }
        }
        let invoicedOwner = selectedOwnership.titleOwners.filter(function (
            titleOwner
        ) {
            return titleOwner.invoiced
        })
        if (invoicedOwner.length > 1) {
            issueWarnMessage("Ownership cannot have more than one invoiced owner")
            return false
        } else if (invoicedOwner.length <= 0) {
            issueWarnMessage("Ownership must have an invoiced owner")
            return false
        }
        setSelectedOwnership(selectedOwnership)
        return true
    }

    function saveNote() {
        let data = {
            titleId: selectedOwnership.id,
            note: note,
        }
        saveOwnershipNote(data).then((response) => {
            issueResponseMessage(response)
            handleNoteClose()
        })
    }

    function terminateOwnership(data) {
        ownershipTermination(data).then((response) => {
            issueResponseMessage(response)
            getOwnerships(
                searchReference,
                searchOwner,
                searchUnit,
                paginationModel.page,
                paginationModel.pageSize
            ).then((ownerships) => setOwnerships(ownerships))
            getTotalOwnerships(searchReference, searchOwner, searchUnit).then(
                (ownerships) => setTotalOwnerships(ownerships.totalTitles)
            )
            handleTerminateClose()
        })
    }

    function showAddNewUserForm(idNoSearch, idTypeSearch) {
        setIdType(idTypeSearch)
        setIdNo(idNoSearch)
        setTenantModal(false)
        setAddUserModal(true)
    }

    function showAddNewContactForm(idNoSearch, idTypeSearch) {
        setIdType(idTypeSearch)
        setIdNo(idNoSearch)
        setTenantModal(false)
        setAddUserModal(true)
        setIsContactSelect(true)
    }

    function showAddNewOrgForm(companySearch) {
        setCompanySearch(companySearch)
        setAddOrganizationModal(true)
    }

    function handleAddUserClose() {
        setAddUserModal(false)
    }

    function handleAddOrgClose() {
        setAddOrganizationModal(false)
    }

    const columns = useMemo(
        () => [
            {
                headerName: "Billing Code",
                field: "billingCode",
                minWidth: 200,
                flex: 2,
            },
            {headerName: "Unit", field: "unitName", minWidth: 200, flex: 2},
            {headerName: "Taxable", field: "taxableText", minWidth: 150, flex: 1.5},
            {
                headerName: "Periodic Charge",
                field: "periodicCharge",
                minWidth: 175,
                flex: 1.75,
                sortComparator: sortNumeric,
                valueFormatter: (row) => {
                    return formatCurrency(row.value)
                },
            },
            {
                headerName: "Owner(s)/Tenant",
                field: "ownersAndTenantName",
                minWidth: 375,
                flex: 3.75,
                renderCell: renderCellExpand,
            },
            {
                headerName: "Balance",
                field: "titleBalance",
                minWidth: 150,
                flex: 1.5,
                sortComparator: sortNumeric,
                valueFormatter: (row) => {
                    return formatCurrency(row.value)
                },
            },
            {
                headerName: "Actions",
                field: "actions",
                width: 100,
                type: "actions",
                getActions: (params) => {
                    let arr = []
                    arr.push(
                        <GridActionsCellItem
                            icon={<Visibility/>}
                            onClick={() => handleViewClick(params.row)}
                            label="View"
                            showInMenu
                        />
                    )
                    arr.push(
                        <GridActionsCellItem
                            icon={<Add/>}
                            label="Add Note"
                            onClick={() => handleNoteClick(params.row)}
                            showInMenu
                        />
                    )

                    if (
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS" ||
                        user.proxyRole === "ACC"
                    ) {
                        arr.push(
							<GridActionsCellItem
								icon={<Contacts />}
								label="Next of Kin"
								onClick={() => handleContactClick(params.row)}
								showInMenu
							/>
						);
                        arr.push(
                            <GridActionsCellItem
                                icon={<LocalAtm/>}
                                label="Receive Payment"
                                onClick={() => handlePaymentClick(params.row)}
                                showInMenu
                            />
                        )
                        arr.push(
                            <GridActionsCellItem
                                icon={<CreditCard/>}
                                label="Issue Credit"
                                onClick={() => handleCreditClick(params.row)}
                                showInMenu
                            />
                        )
                        arr.push(
                            <GridActionsCellItem
                                icon={<NearMe/>}
                                label="Issue Charge"
                                onClick={() => handleChargeClick(params.row)}
                                showInMenu
                            />
                        )
                    }
                    if (user.proxyRole === "BLDIR" || user.proxyRole === "SYSAD") {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Edit/>}
                                label="Edit"
                                onClick={() => handleEditOwnershipClick(params.row)}
                                showInMenu
                            />
                        )
                        arr.push(
                            <GridActionsCellItem
                                icon={<Clear/>}
                                label="Terminate"
                                onClick={() => handleTerminateClick(params.row)}
                                showInMenu
                            />
                        )
                    }
                    return arr
                },
            },
        ]
        //     [],
    )

  function handlePrintOwnershipStatement() {
    printOwnershipStatement(selectedOwnership.id, dayjs(statementYear).year())
  }

    function handlePrintOwnership() {
        printOwnership(selectedOwnership.id)
    }

  return (
    <Permit
      roles="BLDIR,BLDMG,REC,CS"
      services="HOA"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <MainContainer>
        {task !== "View" && (
          <TopBarContainer
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              width: "100%",
              flexWrap: "nowrap",
            }}
            container
                      >
            <ResponsiveRow
              item
              sx={{
                width: { xs: "100%", lg: "auto" },
                flexWrap: { xs: "wrap", lg: "nowrap" },
                justifyContent: { xs: "center", lg: "flex-start" },
                padding: 0,
              }}
            >
              <FormControl sx={{ marginBottom: "5px" }}>
                                <InputLabel>Search Billing Code</InputLabel>

                                <OutlinedInput
                                    id="searchRef"
                                    label="Search Billing Code"
                                    sx={{
                                        width: {xs: "100%", lg: "90%"},
                                    }}
                                    onChange={(event) => onTextChange(event)}
                                />
                            </FormControl>
                            <FormControl sx={{ marginBottom: "5px" }}>
                                <InputLabel>Search Unit</InputLabel>

                                <OutlinedInput
                                    id="searchUnit"
                                    label="Search Unit"
                                    sx={{
                                        width: {xs: "100%", lg: "90%"},
                                    }}
                                    onChange={(event) => onTextChange(event)}
                                />
                            </FormControl>

                            <FormControl sx={{ marginBottom: "5px" }}>
                                <InputLabel>Search Owner</InputLabel>

                                <OutlinedInput
                                    id="searchOwner"
                                    label="Search Owner"
                                    sx={{
                                        width: {xs: "100%", lg: "90%"},
                                    }}
                                    onChange={(event) => onTextChange(event)}
                                />
                            </FormControl>
                        </ResponsiveRow>

                        <Permit roles="BLDIR,BLDMG,ACC,CS">
                            <ResponsiveRow
                                item
                                sx={{
                                    width: {xs: "100%", lg: "70%"},
                                    flexWrap: {xs: "wrap", lg: "nowrap"},
                                    justifyContent: {xs: "center", lg: "flex-end"},
                                    padding: 0,
                                }}
                            >
                                <TopBarContainedBrandButton
                                    sx={{width: {xs: "100%", lg: "200px"}, height: "55px"}}
                                    onClick={handleAddOwnershipClick}
                                >
                                    Add Ownership
                                </TopBarContainedBrandButton>

                                <SplitButton buttons={ownershipButtons} height="auto"/>
                                <SplitButton buttons={resendButtons} height="auto"/>
                            </ResponsiveRow>
                        </Permit>
                    </TopBarContainer>
                )}
                <br/>

                {/*ADD NEW USER MODAL*/}
                <AddNewUser
                    openModal={addUserModal}
                    closeModal={handleAddUserClose}
                    saveUser={handlePersonOrgSelect}
                    manageUsers={false}
                    searchedIdType={idTypeSearch}
                    searchedIdNo={idNoSearch}
                />

                {/*ADD NEW ORGANISATION MODAL*/}
                <AddNewOrganisation
                    openModal={AddOrganizationModal}
                    saveOrg={handlePersonOrgSelect}
                    searchedName={companySearch}
                    handleSave={true}
                    closeModal={handleAddOrgClose}
                />

                {(task === "Create" || task === "Edit") && (
                    <CreateOwnershipModal
                        task={task}
                        saveOwnership={saveOwnership}
                        updateOwnership={updateOwnership}
                        handleAddOwnershipClose={handleAddOwnershipClose}
                        handleEndDateChange={handleEndDateChange}
                        handleOwnershipChargeItemAdd={handleOwnershipChargeItemAdd}
                        handleStartDateChange={handleStartDateChange}
                        handleTenantSwitchToggle={handleTenantSwitchToggle}
                        handleUnitChange={handleUnitChange}
                        handleBalanceChange={handleBalanceChange}
                        handleBalanceNotesChange={handleBalanceNotesChange}
                        setIsTenantSelect={setIsTenantSelect}
                        setIsHomeOwnerSelect={setIsHomeOwnerSelect}
                        setTenantModal={setTenantModal}
                        chargeItemsTotal={chargeItemsTotal}
                        endDate={endDate}
                        balance={balance}
                        balanceNotes={balanceNotes}
                        homeOwnersTableRows={homeOwnersTableRows}
                        facilityOptions={facilityOptions}
                        ownershipChargesTableRows={ownershipChargesTableRows}
                        selectedOwnership={selectedOwnership}
                        startDate={startDate}
                        selectedUnit={selectedUnit}
                        tenantOccupied={tenantOccupied}
                        unitOptions={unitOptions}
                    />
                )}

                {task === "BulkEdit" && (
                    <BulkEditOwnershipsModal
                        handleBulkEditOwnershipClose={handleBulkEditOwnershipClose}
                        handleOwnershipChargeItemAdd={handleOwnershipChargeItemAdd}
                        bulkEditOwnershipClick={bulkEditOwnershipClick}
                        chargeItemsTotal={chargeItemsTotal}
                        ownershipChargesTableRows={ownershipChargesTableRows}
                        task={task}
                        unitDetailTableRows={unitDetailTableRows}
                    />
                )}

                {task === "View" && (
                    <>
                        <TopBarContainer
                            container
                            sx={{
                                width: {xs: "100%", lg: "80%"},
                                boxShadow: "4px 4px 12px #aaa",
                            }}
                        >
                            <ResponsiveRow
                                item
                                sx={{
                                    width: {xs: "100%", lg: "25%"},
                                    justifyContent: {xs: "center", lg: "flex-start"},
                                    padding: 0,
                                }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <DatePicker
                                        sx={{width: "90%"}}
                                        id={"statementYear"}
                                        value={dayjs(statementYear)}
                                        disableFuture={true}
                                        disablePast={false}
                                        openTo="year"
                                        views={["year"]}
                                        label="Statement Year"
                                        isrequired={true}
                                        onChange={(date) => handleStatementYearChange(date)}

                                    />
                                </LocalizationProvider>
                            </ResponsiveRow>
                            <ResponsiveRow
                                item
                                sx={{
                                    width: {xs: "100%", lg: "75%"},
                                    justifyContent: {xs: "center", lg: "flex-end"},
                                    padding: 0,
                                }}
                            >
                                <TopBarContainedBrandButton
                                    sx={{width: {xs: "100%", lg: "250px"}}}
                                    onClick={handlePrintOwnershipStatement}
                                >
                                    Print Ownership Statement
                                </TopBarContainedBrandButton>

                                <TopBarContainedBrandButton
                                    sx={{width: {xs: "100%", lg: "200px"}}}
                                    onClick={handlePrintOwnership}
                                >
                                    Print Ownership
                                </TopBarContainedBrandButton>
                                <TopBarContainedCancelButton
                                    onClick={() => setTask("")}
                                    sx={{width: {xs: "100%", lg: "200px"}}}
                                >
                                    Close
                                </TopBarContainedCancelButton>
                            </ResponsiveRow>
                        </TopBarContainer>
                        <br/>

                        <ResponsiveRow
                            sx={{width: "100%", padding: "0", justifyContent: "center", height: "auto"}}
                        >
                            <DetailsCardContainer sx={{padding: "30px 20px"}}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h5"
                                    textAlign={"start"}
                                >
                                    Ownership Information for{" "}
                                    {selectedOwnership && selectedOwnership.ownerNames}
                                </Typography>
                                <DetailsContainer
                                    sx={{
                                        width: {xs: "100%", lg: "70vw"},
                                        height: "auto",
                                        margin: "auto",
                                        alignItems: "flex-start",
                                    }}
                                    container
                                >
                                    <DetailsColumn sx={{width: {xs: "100%", lg: "25%"}}}>
                                        <Typography
                                            sx={{marginBottom: "20px", color: "#037171"}}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h5"
                                        >
                                            Ownership Information
                                        </Typography>

                                        <DetailsRow>
                                            <DetailsLabel> Billing Code: </DetailsLabel>
                                            <DetailsText>
                                                {selectedOwnership && selectedOwnership.billingCode}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel> Start Date: </DetailsLabel>
                                            <DetailsText>
                                                {formatDate(
                                                    selectedOwnership && selectedOwnership.startDate
                                                )}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel> Periodic Charge: </DetailsLabel>
                                            <DetailsText>
                                                {formatCurrency(
                                                    selectedOwnership && selectedOwnership.periodicCharge
                                                )}
                                            </DetailsText>
                                        </DetailsRow>
                                    </DetailsColumn>
                                    <DetailsColumn sx={{width: {xs: "100%", lg: "25%"}}}>
                                        <Typography
                                            sx={{marginBottom: "20px", color: "#037171"}}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h4"
                                        >
                                            Property Information
                                        </Typography>

                                        <DetailsRow>
                                            <DetailsLabel> Property: </DetailsLabel>
                                            <DetailsText>
                                                {selectedOwnership && selectedOwnership.facilityName}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel>Unit: </DetailsLabel>
                                            <DetailsText>
                                                {selectedOwnership && selectedOwnership.unitName}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel> Tenant: </DetailsLabel>
                                            <DetailsText>
                                                {selectedOwnership && selectedOwnership.tenantName}
                                            </DetailsText>
                                        </DetailsRow>
                                    </DetailsColumn>

                                    <DetailsColumn sx={{width: {xs: "100%", lg: "25%"}}}>
                                        <Typography
                                            sx={{marginBottom: "20px", color: "#037171"}}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h4"
                                        >
                                            Status Information
                                        </Typography>

                                        <DetailsRow>
                                            <DetailsLabel>Status: </DetailsLabel>
                                            <DetailsText>
                                                {selectedOwnership && selectedOwnership.status.name}
                                            </DetailsText>
                                        </DetailsRow>
                                        {/* <DetailsRow>
                      <DetailsLabel> Owner Name: </DetailsLabel>
                      <DetailsText>
                        {selectedOwnership && selectedOwnership.ownerNames}
                      </DetailsText>
                    </DetailsRow>*/}
                                        <DetailsRow>
                                            <DetailsLabel>Ownership Balance : </DetailsLabel>
                                            <DetailsText>
                                                {formatCurrency(
                                                    selectedOwnership && selectedOwnership.titleBalance
                                                )}
                                            </DetailsText>
                                        </DetailsRow>
                                    </DetailsColumn>
                                    <DetailsColumn sx={{width: {xs: "100%", lg: "25%"}}}>
                                        <Typography
                                            sx={{marginBottom: "20px", color: "#037171"}}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h4"
                                        >
                                            Other Information
                                        </Typography>
                                        <DetailsRow>
                                            <DetailsLabel>Invoiced Home Owner: </DetailsLabel>
                                            <DetailsText>
                                                {selectedOwnership &&
                                                    selectedOwnership.invoicedOwner &&
                                                    selectedOwnership.invoicedOwner.name}
                                            </DetailsText>
                                        </DetailsRow>

                                        {selectedOwnership && (
                                            <DetailsRow>
                                                <DetailsLabel>Other Home Owner(s):</DetailsLabel>
                                                <ResponsiveRow
                                                    sx={{
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",

                                                        height: "50px",

                                                        overflowY: "auto",
                                                        backgroundColor: "#efefef",
                                                        borderRadius: "10px",
                                                    }}
                                                >
                                                    {selectedOwnership.titleOwners
                                                        .filter((o) => o.invoiced !== true)
                                                        .map((owner) => {
                                                            return (
                                                                <DetailsText
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: 600,
                                                                        marginBottom: "4px",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    {owner.name}
                                                                </DetailsText>
                                                            )
                                                        })}
                                                </ResponsiveRow>
                                            </DetailsRow>
                                        )}
                                    </DetailsColumn>
                                </DetailsContainer>
                            </DetailsCardContainer>

                            <ResponsiveRow
                                sx={{
                                    alignItems: "flex-start",
                                    justifyContent: "space-evenly",
                                    flexWrap: {xs: "wrap", lg: "nowrap"},
                                    margin: "auto",
                                    padding: "10px 0",
                                }}
                                container
                            >
                                <NoteLogsCard
                                    width="33.3%"
                                    height={"500px"}
                                    notesMode={"Ownership"}
                                    contract={selectedOwnership}
                                />
                                <OwnershipChargesCard
                                    width={"33.3%"}
                                    height={"500px"}
                                    selectedOwnership={selectedOwnership}
                                />

                                <DocumentStore
                                    files={[
                                        ...selectedOwnership.imageFiles,
                                        ...selectedOwnership.otherDocuments,
                                    ]}
                                    item={selectedOwnership}
                                    type={"Ownership"}
                                    width={"33.3%"}
                                    height={"500px"}
                                    // closeView={}
                                    // uploadFile={uploadFile}
                                    // deleteFile={deleteFile}
                                />
                            </ResponsiveRow>
                            <br/>

                            {/* Shared Transaction History */}
                            <TransactionHistory
                                transactionMode={"Ownership"}
                                contract={selectedOwnership}
                                handleVoidClick={handleVoidClick}
                            />
                            <br/>
                        </ResponsiveRow>
                        <br/>
                    </>
                )}

                {task !== "View" && (
                    <MuiDataGrid
                        dataGridColumns={columns}
                        dataGridRows={ownerships}
                        loading={loading}
                        height="65vh"
                        totalRows={totalOwnerships}
                        handlePageLoad={setPaginationModel}
                        handleSelectedRows={setRowSelectionModel}
                        currentSelectionModel={rowSelectionModel}
                        serverPagination={true}
                    />
                )}

                {/*RECEIVE PAYMENT MODAL*/}
                <ReceivePayment
                    showPaymentModal={paymentModal}
                    handlePaymentClose={handlePaymentClose}
                    paymentMode={"Ownership"}
                    contract={selectedOwnership}
                    savePayment={savePayment}
                />
                {/*CREATE CREDIT MODAL*/}
                {creditModal && (
                    <CreditNote
                        open={creditModal}
                        onClose={handleCreditClose}
                        voiditem={voiditem}
                        ownershipid={selectedOwnership && selectedOwnership.id}
                    />
                )}
                {/*CREATE CHARGE MODAL*/}
                {chargeModal && (
                    <DebitNote
                        open={chargeModal}
                        onClose={handleChargeClose}
                        voiditem={voiditem}
                        ownershipid={selectedOwnership && selectedOwnership.id}
                        ownershipIds={
                            bulkChargeAction && rowSelectionModel.length > 1
                                ? rowSelectionModel
                                : []
                        }
                        bulkChargeAction={bulkChargeAction}
                    />
                )}

                {/*ADD NOTE MODAL*/}
                <AddNoteModal
                    handleNoteClose={handleNoteClose}
                    onTextChange={onTextChange}
                    noteModal={noteModal}
                    note={note}
                    saveNote={saveNote}
                    selectedOwnership={selectedOwnership}
                />

                {/*Download/Export Document via Email... Shared Modal*/}
                {downloadModal && (
                    <ExportDocumentsModal
                        showExportDocumentsModal={downloadModal}
                        handleExportDocumentsClose={handleDownloadClose}
                        title={titleText}
                        severity={severityText}
                        body={bodyText}
                        contract={"Ownership"}
                        handleIncludeDetailToggle={handleIncludeOwnershipDetailToggle}
                        handleStatementYearChange={handleStatementYearChange}
                        includeDetails={includeOwnershipDetail}
                        statementYear={statementYear}
                        handleExportDocuments={positiveExportDocsAction}
                        handleCancel={handleDownloadClose}
                    />
                )}

                {/*TERMINATE MODAL*/}
                <ContractTermination
                    showTerminateModal={terminateModal}
                    handleTerminateClose={handleTerminateClose}
                    terminationMode={"Ownership"}
                    terminateItem={terminateItem}
                    contract={selectedOwnership}
                    terminateContract={terminateOwnership}
                />

                {/*CONFIRMATION MODAL*/}
                {showConfirmationModal && (
                    <ConfirmationModal
                        showConfirmationModal={showConfirmationModal}
                        handleConfirmationClose={handleConfirmationClose}
                        title={titleText}
                        invoiceTypes={invoiceTypes}
            setInvoiceTypes={setInvoiceTypes}
            severity={severityText}
            body={bodyText}
            positiveText={positiveText}
            negativeText={negativeText}
            positiveAction={positiveAction}
            negativeAction={handleConfirmationClose}
          />
        )}

                {/*PERSON/ORG SEARCH MODAL*/}
                <PersonOrgSearch
                    contractType={'ownership'}
                    showPersonOrgModal={tenantModal}
                    handlePersonOrgClose={handlePersonOrgClose}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    showAddNewUserForm={showAddNewUserForm}
                    showAddNewOrgForm={showAddNewOrgForm}
                />

                {/*OWNERSHIP CONTACT MODAL*/}
                <ContractContactModal
                    handleContactClose={handleContactClose}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    handleContactClear={handleContactClear}
                    handleIdTypeChange={handleIdTypeChange}
                    searchPersonOrg={searchPersonOrg}
                    contactModal={contactModal}
                    showAddNewContact={showAddNewContact}
                    showAddNewContactForm={showAddNewContactForm}
                    contactTableRows={contactTableRows}
                    foundTenant={foundTenant}
                    idNoSearch={idNoSearch}
                    idTypes={idTypes}
                    onTextChange={onTextChange}
                    selectedContract={selectedOwnership}
                    contractType={"Ownership"}
                />
            </MainContainer>
        </Permit>
    )
}
